import axios from 'axios';
import React, { Component } from 'react';
import { Link ,Redirect} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import '../../assets/css/custom-media-queries.css';
import '../../assets/css/custom-style.css';
import Footer from '../../layout/Footer';
import { createNotification } from '../helpers/Notification';
import { getApiUrl } from '../helpers/Util';


import { setLoginUserData } from '../project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email:"",
            password:"",
            isAuthenticated:false,
            loader:false
        }
        this.validator = new SimpleReactValidator();
    }
 
    handelOnChange = (event) =>{
        if(event.target.name === 'email'){
            this.setState({email:event.target.value});
        }else if(event.target.name === 'password'){
            this.setState({password:event.target.value})
        }
    }

    onKeyUp = (event) => {
        if (event.charCode === 13) {
          this.handelLogin();
        }
    }

    handelLogin = async () => {
        const { setLoginUserData, loginUserData } = this.props;
        let loginUserDataClone = { ...loginUserData };

        if (this.validator.allValid()) {
        const {email,password} = this.state;
       
        const payload = {email,password};
        const headers = {headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        }}
       
        const URL =   getApiUrl();
        this.setState({loader:true});

        
        axios.post(`${URL}/login`, payload, {
            headers: headers
          })                                                                            
          .then((response) => {
            if(response.status === 200){
               
                        let result = response.data;
                        this.setState({isAuthenticated:true});
                        localStorage.setItem('user-info',JSON.stringify(response.data));
                        localStorage.setItem('userName',result.data.profile.name);
                        localStorage.setItem('user-token',result.data.token);
                        localStorage.setItem('userId',result.data.profile.id);
                        localStorage.setItem('profileImage',result.data.profile.profileImage);  
                        localStorage.setItem('roleKey',result.data.profile.roleKey);
                        this.props.history.push('/dashboard');
                        setLoginUserData({ ...loginUserDataClone, userName: result.data.profile.name,profileImage: result.data.profile.profileImage, roleKey:result.data.profile.roleKey});
                        this.setState({loader:false});
                        this.props.updateRoleKey(result.data.profile.roleKey);

             }
          })
        //   .catch((error) => {
        //       createNotification("error", error.response.data.error.message);
        //       this.setState({loader:false});
             
        //     console.log({resetPasswordError:error.response.data.error.message});
        //   })

        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        // if( localStorage.getItem('user-token') && localStorage.getItem('user-token') !==""){
        //     return <Redirect to='/dashboard'  />;
        // }
        return (
            <>
            <main className="user-account-page">
                <div className="container-fluid">
                    <section className="form-container">
                        <div className="row">
                            <div className="col-md-6 logo-col">
                                <div className="logo-content-frame">
                                    <h1 className="logo-frame">
                                        <span className="logo"></span>
                                    </h1>
                                    <h2 className="title">Welcome, User</h2>
                                    <label className="lbl">Login to continue the access</label>
                                </div>
                            </div>
                            <div className="col-md-6 form-col">
                                <div className="content-frame">
                                    <h2 className="title">Sign in to STEHL Corp</h2>

                                    <section className="form-frame login-form">
                                        <div className="form-group mb-3">
                                            <input type="text" name="email" placeholder="Email" className="form-control" value={this.state.email} onChange={this.handelOnChange} onKeyPress={(e)=>this.onKeyUp(e)} />
                                            {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                                        </div>

                                        <div className="form-group mb-3">
                                            <input type="password" name="password" placeholder="Password" className="form-control" onChange={this.handelOnChange} value={this.state.password} onKeyPress={(e) => this.onKeyUp(e)} />
                                            {this.validator.message('password', this.state.password, 'required', { className: 'text-danger' })}
                                        </div>

                                        <p className="lbl-forgot-pswd"> <Link to="/forgotpassword"> Forgot Password? </Link></p>

                                        <div className="btn-row">
                                            <input type="button" name="signIn" value="Sign In" className="btn-rnd-black btn-signIn"  onClick={()=>this.handelLogin()} />
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
            
            </>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLoginUserData: setLoginUserData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);

