import axios from 'axios';
import React, { Component } from 'react'
import { DropDown } from '../common';
import { getLoginToken, dateFormat, dbDateFormat, convertToGmt } from '../helpers/Util';
import { createNotification } from '../helpers/Notification';
import { getManagerList } from './Helper';
import SimpleReactValidator from 'simple-react-validator';
import { getApiUrl } from '../helpers/Util'
import DatePicker from "react-datepicker";
import AlertBox from "../common/AlertBox";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
export default class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managerList: [],
            managerId: "",
            jobNumber: "0000",
            startDate: "",
            endDate: "",
            projectName: "",
            description: "",
            contract_price: "",
            total_cost: "",
            amount_billed: "",
            cost_percentage: "",
            balance_billed: "",
            due_retention: "",
            addItem: "",
            scopeItemData: [],
            organization: 1,
            isShowAlertBox: false,
            addItemVal: "",
            scopeItemLengthLimitFlag: false,
        }
        this.itemRef = React.createRef();
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {

        this.generateJobNumber();
        getManagerList().then((response) => {
            this.generateJobNumber();
            this.setState({ managerList: [...response] });
        })
    }

    handleCloseAlert = () => {
        this.setState({ isShowAlertBox: false })
    }

    handleOnChange = (event) => {
        if (event.target.name === "jobNumber") {
            this.setState({ jobNumber: event.target.value });
        } else if (event.target.name === "startDate") {
            this.setState({ startDate: event.target.value });
        } else if (event.target.name === "endDate") {
            this.setState({ endDate: event.target.value });
        } else if (event.target.name === "projectName") {
            this.setState({ projectName: event.target.value });
        } else if (event.target.name === "description") {
            this.setState({ description: event.target.value });
        } else if (event.target.name === "description") {
            this.setState({ description: event.target.value });
        } else if (event.target.name === "projectManager") {
            this.setState({ managerId: event.target.value });
        } else if (event.target.name === "addItem") {
            this.setState({ addItemVal: event.target.value, scopeItemLengthLimitFlag: false });
        } else if (event.target.name === "total_cost") {
            this.setState({ total_cost: event.target.value });
        } else if (event.target.name === "contract_price") {
            this.setState({ contract_price: event.target.value });
        }
        else if (event.target.name === "amount_billed") {
            this.setState({ amount_billed: event.target.value });
        } else if (event.target.name === "cost_percentage") {
            this.setState({ cost_percentage: event.target.value });
        } else if (event.target.name === "balance_billed") {
            this.setState({ balance_billed: event.target.value });
        } else if (event.target.name === "due_retention") {
            this.setState({ due_retention: event.target.value });
        }
    }

    handleDateChange = (date, type) => {

        let formatedDate = date
        // if (type === "startDate") {
        //     this.setState({ startDate: formatedDate });
        //  }else if (type === "endDate") {
        //      this.setState({ endDate: formatedDate });
        //  }


        if (type === "startDate") {
            if (this.state.endDate !== '') {
                if (Date.parse(this.state.endDate) < Date.parse(date)) {
                    createNotification("error", "Start date should be less than End date");
                } else {
                    this.setState({ startDate: formatedDate });
                }
            } else {
                this.setState({ startDate: formatedDate });
            }
        } else if (type === "endDate") {
            if (this.state.startDate !== '') {
                if (Date.parse(this.state.startDate) > Date.parse(date)) {
                    createNotification("error", "End date should be greater than Start Date");
                } else {
                    this.setState({ endDate: formatedDate });
                }
            } else {
                this.setState({ endDate: formatedDate });
            }
        }

    }

    getManagerList = () => {

        const token = getLoginToken();
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }

        const URL = getApiUrl();
        const url = `${URL}/users/projectmanagers`;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status) {
                    let responseData = response.data.data;
                    let listArr = [];
                    responseData && responseData.map((item) => {
                        listArr.push({ lable: item.name, value: item.id });
                    });
                    this.setState({ managerList: listArr });

                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');

                } else {
                    createNotification("error", error.response.data.error.message);
                }
            })
    }

    generateJobNumber = () => {
        const token = getLoginToken();
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }

        const URL = getApiUrl();
        const url = `${URL}/projects/getNextJobNumber`;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status) {
                    let responseData = response.data.data;
                    this.setState({ jobNumber: String(responseData) });

                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log({ resetPasswordError: error });
            })
    }

    addMoreItem = () => {
        if (!this.containsDuplicate()) {
            if (this.state.addItemVal.trim() !== "") {
                if (this.state.addItemVal.length <= 300) {
                    let itemsClone = [...this.state.scopeItemData];
                    let newItemArr = [...itemsClone, { title: this.state.addItemVal }]
                    this.setState({ scopeItemData: newItemArr, addItemVal: "" });
                } else {
                    this.setState({ scopeItemLengthLimitFlag: true })
                }

            }
        } else {
            createNotification("error", "Item already exists in the list")
        }


    }
    containsDuplicate = () => {
        const { scopeItemData, addItemVal } = this.state;
      
        let itemsClone = [...scopeItemData];
           for (let x of itemsClone) {
            if (x.title === addItemVal.trim()) {
                return (true);
            }
        }
        return (false);
    }

    changeItem = (event) => {

        let rowIndex = event.target.id;
        const { scopeItemData } = this.state;
        let itemArr = [...scopeItemData];

        itemArr[rowIndex] = { title: event.target.value };
        this.setState({ scopeItemData: [...itemArr] });
    }

    removeItem = (rowIndex) => {
        const { scopeItemData } = this.state;
        let itemArr = [...scopeItemData];
        itemArr.splice(rowIndex, 1);
        this.setState({ scopeItemData: [...itemArr] });
    }

    checkScopeEmpty = (scopeItemData) => {
        let result = false;
        for (let i = 0; i < scopeItemData.length; i++) {
            // console.log(`${i} ${list[i]}`)
            if (scopeItemData[i].title.trim() === '') {
                createNotification("error", "Scope Item should not be blank!");
                result = true;
                break;

            }
        }
        return result;
    }

    handleSaveProject = (isSaveClick = false) => {
        const { managerId, jobNumber, startDate, endDate, projectName, description, scopeItemData, organization, contract_price, amount_billed, balance_billed, due_retention, total_cost, cost_percentage } = this.state;
        if (this.state.addItemVal !== "") {
            this.setState({ isShowAlertBox: true })
            return;
        }
        if (this.validator.allValid() && !this.checkScopeEmpty(scopeItemData)) {

            const token = getLoginToken();

            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
            this.setState({ loader: true });
            const URL = getApiUrl();
            let url = "";
            const data = { title: projectName, organization, manager: managerId, jobNumber: Number(jobNumber), startDate: dbDateFormat(startDate), endDate: dbDateFormat(endDate), description, scopeItemData, contract_price: Number(contract_price), amount_billed: Number(amount_billed), balance_billed: Number(balance_billed), cost_percentage: Number(cost_percentage), due_retention: Number(due_retention), total_cost: Number(total_cost) };


            url = `${URL}/projects`;
            axios.post(url, data, {
                headers: headers
            })
                .then((response) => {
                    if (response.status === 200) {

                        createNotification("success", "Project added successfully!");

                        if (isSaveClick) {
                            this.props.history.push(`/project/edit/${response.data.data.id}`);
                        } else {
                            this.props.history.push(`/project/drawing/${response.data.data.id}`);
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        createNotification("error", "Your login session has been expired!");
                        localStorage.clear();
                        this.props.history.push('/login');
                    } else {
                        createNotification("error", error.response.data.error.message);
                    }
                    console.log({ resetPasswordError: error });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    handleOnBlur = (e) => {

        const { total_cost, amount_billed, contract_price } = this.state
     
        if (e.target.name === "total_cost" || e.target.name === "amount_billed") {
            let value;
                value = (total_cost / amount_billed) * 100;
                this.setState({ cost_percentage: value.toFixed(2)});
            
        }
    }
    handleOnblurBalanceBill = (e) => {
        const { total_cost, amount_billed, contract_price } = this.state
        if (e.target.name === "amount_billed" || e.target.name === "contract_price") {

            let value;
            
                value = contract_price - amount_billed;
               
               if(value>=0){            
                this.setState({ balance_billed: value.toFixed(3) });
               }else{
                createNotification("error","Contract Price Should be greater or equal to the 'Amount Billed' value.");
                this.setState({ balance_billed: "" });
               }
                
        }
    }
    render() {
        const { jobNumber, managerList, projectName, startDate, endDate, managerId, description, scopeItemData, loader ,contract_price,total_cost,amount_billed,cost_percentage,balance_billed,due_retention} = this.state;
        return (

            <div>
                {/* <AllowedTo
                    perform={Permission.USER_READ}
                    no={() =><AccessDenied />}
                > */}
                <div className="main-content">
                    <AlertBox
                        openModal={this.state.isShowAlertBox}
                        alertMsg="Please click on + button to add scope item."
                        handleConfirmModal={this.handleConfirmModal}
                        handleClose={this.handleCloseAlert}
                    />
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/project`}><li className="breadcrumb-item">Projects</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/project/add`}><li className="breadcrumb-item">Create Project</li></Link>
                                </ol>
                            </div>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">Create Project</h4>
                                {/* <div className="project-button-box">
                            <button type="button" className="btn btn-primary waves-effect waves-light">
                                <i className="uil uil-edit"></i> Save
                            </button>
                        </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body bgW">
                                            <div className="wizard clearfix">
                                                <div className="content clearfix">
                                                    <form className="form-project">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom01">Project Name</label>
                                                                    <input type="text" className="form-control" id="validationCustom01" placeholder="Project name" value={projectName} name="projectName" onChange={(e) => this.handleOnChange(e)} />
                                                                    {this.validator.message('Project Name', projectName, 'required|max:200', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom02">Project Manager</label>
                                                                    <DropDown
                                                                        name="projectManager"
                                                                        className="mb-0"
                                                                        firstOption={{ lable: 'Select Project Manager', value: 0 }}
                                                                        options={managerList}
                                                                        onChange={(e) => this.handleOnChange(e)}
                                                                        //defaultSelected={this.state.managerId}
                                                                        lable=""
                                                                    />
                                                                    {this.validator.message('projectManager', managerId, 'required', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom03">Description</label>
                                                                    <textarea className="form-control" rows="3" name="description" onChange={(e) => this.handleOnChange(e)} >{description}</textarea>
                                                                    {this.validator.message('description', description, 'required|max:700', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom01">Start Date</label>
                                                                    <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "startDate")} dateFormat="P" selected={startDate}
                                                                        placeholderText="MM/DD/YYYY" />

                                                                    {this.validator.message('startDate', startDate, 'required', { className: 'text-danger' })}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom02">End Date </label>
                                                                    <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "endDate")} dateFormat="P" selected={endDate}
                                                                        minDate={startDate}
                                                                        placeholderText="MM/DD/YYYY"
                                                                    />
                                                                    {this.validator.message('endDate', endDate, 'required', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom01">Job Number</label>
                                                                    <input type="number" name="jobNumber" className="form-control" id="validationCustom01"  min={0} value={this.state.jobNumber} onChange={(e) => this.handleOnChange(e)} />
                                                                    {this.validator.message('jobNumber', this.state.jobNumber, 'required|integer|min:2|max:6', { className: 'text-danger' })}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom01">Contract Price</label>
                                                                    <input type="number" name="contract_price" className="form-control" id="validationCustom01"  min={0}  value={contract_price} onChange={(e) => this.handleOnChange(e)}
                                                                        onBlur={(e) => this.handleOnblurBalanceBill(e)} onKeyUp={(e) => this.handleOnblurBalanceBill(e)}
                                                                    />
                                                                    {this.validator.message('Contract Price', contract_price, 'required|max:20', { className: 'text-danger' })}
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                            <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Total Cost</label>
                                                                            <input type="number" name="total_cost" className="form-control" id="validationCustom01" value={total_cost}
                                                                             min={0}  onChange={(e) => this.handleOnChange(e)} 
                                                                             onBlur={(e)=>this.handleOnBlur(e)}onKeyUp={(e)=>this.handleOnBlur(e)}
                                                                            />
                                                                            {this.validator.message('total cost',total_cost, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                            <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Amount Billed</label>
                                                                            <input type="number" name="amount_billed" className="form-control" id="validationCustom01" 
                                                                             min={0} value={this.state.amount_billed} onChange={(e) => this.handleOnChange(e)} onBlur={(e)=>{this.handleOnBlur(e);this.handleOnblurBalanceBill(e)}}
                                                                            onKeyUp={(e)=>{this.handleOnBlur(e);this.handleOnblurBalanceBill(e)}}/>
                                                                            {this.validator.message('amount billed',amount_billed, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                            </div>

                                                        </div>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                            <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01"> Cost Percentage</label>
                                                                            <input type="number" name="cost_percentage" className="form-control" id="validationCustom01" value={this.state.cost_percentage} onChange={(e) => this.handleOnChange(e)} disabled />
                                                                            {this.validator.message('cost percentage',cost_percentage, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                            <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Balance Billed</label>
                                                                            <input type="number" name="balance_billed" className="form-control" id="validationCustom01" disabled value={this.state.balance_billed} onChange={(e) => this.handleOnChange(e)} />
                                                                            {this.validator.message('balance billed',balance_billed, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label" htmlFor="validationCustom01">Due Retention</label>
                                                                    <input type="number" name="due_retention" className="form-control"  min={0} id="validationCustom01" value={this.state.due_retention} onChange={(e) => this.handleOnChange(e)} />
                                                                    {this.validator.message('due retention', this.state.due_retention, 'required|max:20', { className: 'text-danger' })}
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <h4 className="card-title">Add Scope of Item</h4>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6">
                                                                <div className="d-flex align-items-center justify-content-end">
                                                                    <input type="text" className="form-control" placeholder="Add item" name="addItem" id="addItem" onChange={(e) => this.handleOnChange(e)}
                                                                        value={this.state.addItemVal} />
                                                                    <button type="button" className="btn btn-plus btn-primary waves-effect waves-light"
                                                                        onClick={() => this.addMoreItem()}>
                                                                        <i className="uil uil-plus"></i>
                                                                    </button>
                                                                </div>
                                                                {this.state.scopeItemLengthLimitFlag === true ? <p className='text-danger'>Scope item cannot be grater then 300 characters.</p> : ""}
                                                                {this.validator.message('Items', scopeItemData, 'required', { className: 'text-danger' })}
                                                            </div>
                                                        </div>

                                                        {
                                                            scopeItemData && scopeItemData.map((data, index) => {
                                                                return (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3 d-flex align-items-center justify-content-end">
                                                                                <input type="text" className="form-control" placeholder="Add item" name={`item${index}`} value={data.title} id={index} onChange={(e) => this.changeItem(e)} />
                                                                                <button type="button" className="btn btn-plus btn-danger waves-effect waves-light"
                                                                                    onClick={() => this.removeItem(index)}>
                                                                                    <i className="uil uil-minus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })
                                                        }


                                                    </form>
                                                </div>
                                                <div className="actions clearfix">
                                                    <ul role="menu" aria-label="Pagination">
                                                        <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li>
                                                        <li aria-hidden="false" aria-disabled="false" className="" ><a role="menuitem" href="javascript:void(0)" onClick={() => this.handleSaveProject(true)}>Save</a></li>
                                                        <li aria-hidden="false" aria-disabled="false" className="" ><a role="menuitem" href="javascript:void(0)" onClick={() => this.handleSaveProject()}>Next</a></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </AllowedTo> */}
            </div>
        )
    }
}
