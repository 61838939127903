import React, { Component } from "react";
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import { DropDown } from "../common";
import SimpleReactValidator from "simple-react-validator";
import MasterDataService from "../services/MasterDataService";
import ConfirmBox from '../common/ConfirmBox';
import { Link } from "react-router-dom";
import masterDataService from "../services/MasterDataService";

export default class AddFieldCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      organization: "",
      status: "Active",
      loader: false,
      fieldCrewId: null,
      headingText: "Add",
      button: "Save",
      isShowConfirmBox: false,
      projectManagers: [],
      projectManagerId:"",
      pmEmptyFlag:false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.path === "/fieldcrew/:fieldCrewId" && match.params.fieldCrewId !== null) {
      this.setState({
        fieldCrewId: match.params.fieldCrewId,
        headingText: "Edit",
        button: "Update",
      });
      this.getFieldCrewById(match.params.fieldCrewId);
    }
    // projectmanager api calls---
    this.getProjectManagers();
    // console.log({ location: this.props.match });
  };

  getFieldCrewById = (fieldCrewId) => {
    if (fieldCrewId !== "") {
      MasterDataService.getFieldCrewsById(fieldCrewId)
        .then((response) => {
          if (response.status) {
            let resData = response.data.data;
            this.setState({
              name: resData.name,
              email: resData.email,
              phone: resData.phone,
              country: resData.country,
              organization: resData.organization,
              dbImage: resData.upload_fkey ? resData.upload_fkey.filePath : "",
              role: resData.role,
              projectManagerId:resData.manager,
              status: resData.status == "ACTIVE" ? "Active" : "Inactive",
            });
          }
          console.log({ response: response });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.clear();
            this.props.history.push("/login");
            //this.setState({loader:false});
          } else {
            createNotification("error", error.response.data.error.message);
            //this.setState({loader:false});
          }
          console.log({ resetPasswordError: error });
        });
    }
  };

  getProjectManagers = () => {
    masterDataService.getProjectManagers().then((response) => {
      console.log(response);
      let listArr = [];
      let responseData = response.data.data;

      responseData && responseData.map((item) => {
        listArr.push({ lable: item.name.length > 18 ? item.name.substr(0, 20) + ' ...' : item.name, value: item.id })
      });
      this.setState({ projectManagers: listArr })
    }).catch((error) => {
      console.log(error);

    })
  }

  handleResetState = () => {
    this.setState({
      name: "",
      organization: "",
      status: "Active",
      fieldCrewId: null,
      headingText: "Add",
      button: "Save",
    });
  };

  handleOnChange = (event) => {
    if (event.target.name === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target.name === "status") {
      this.setState({ status: event.target.value });

    }else if(event.target.name ==="projectManager"){
      if(event.target.value!=="0"){
        this.setState({projectManagerId:event.target.value,pmEmptyFlag:false})
      }else{
        this.setState({projectManagerId:event.target.value})
      }
      
    }
  };

  onStatusChange = (e) => {
    const { match } = this.props;
    if (this.state.status === "Active" && match.path !== "/addfieldcrew") {
      this.setState({ isShowConfirmBox: true })
    } else {
      this.setState({ status: e.target.value });
    }
  };

  handleConfirmModal = (isYes) => {
    if (isYes) {
      this.setState({ status: "Inactive", isShowConfirmBox: !this.state.isShowConfirmBox });
    } else {
      this.setState({ status: "Active", isShowConfirmBox: !this.state.isShowConfirmBox });
    }
  }

  handleSaveFieldCrew = () => {
    const { name, organization, status, fieldCrewId,projectManagerId } = this.state;
    const { match } = this.props;
    if (this.validator.allValid() && (projectManagerId!=="0")) {
      this.setState({ loader: true });
      let nm = name.trim();
      const data = { name: nm, organization: Number(organization), status,manager:projectManagerId };
      if (match.path === "/fieldcrew/:fieldCrewId" && fieldCrewId !== null) {
        MasterDataService.updateFieldCrewsById(fieldCrewId, data)
          .then((response) => {
            if (response.status === 200) {
              createNotification("success", "Field crew updated successfully!");
              this.handleResetState();
              this.setState({ loader: false });
              this.props.history.push("/fieldcrew");
            }
            console.log({ response });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
              this.setState({ loader: false });
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ loader: false });
            }
            console.log({ resetPasswordError: error });
          });
      } else {
        MasterDataService.addFieldCrew(data)
          .then((response) => {
            if (response.status === 200) {
              createNotification("success", "User is added successfully!");
              this.handleResetState();
              this.setState({ loader: false });
              this.props.history.push("/fieldcrew");
            }
            console.log({ response });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
              this.setState({ loader: false });
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ loader: false });
            }
            console.log({ resetPasswordError: error });
          });
      }
    } else {
      if( projectManagerId=="0"){
        this.setState({pmEmptyFlag:true})
        this.validator.showMessages();
        this.forceUpdate();
      }else{
        this.validator.showMessages();
      this.forceUpdate();
      }
      
    }
  };

  render() {
    const { loader, isShowConfirmBox, projectManagers ,projectManagerId} = this.state;
    console.log(projectManagerId);

    return (
      <div>
        {loader ? (
          <Loader />
        ) : (
          <div className="main-content addUser-form">
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/fieldcrew`}><li className="breadcrumb-item">Field Crew</li></Link>
                    &nbsp; / &nbsp;
                    {this.props.match.path == `/addfieldcrew` ?
                      <Link to={`/addfieldcrew`}><li className="breadcrumb-item">Add</li></Link>
                      : this.props.match.path == `/fieldcrew/:fieldCrewId` ?
                        <Link to={`/fieldcrew/${this.props.match.params.fieldCrewId}`}><li className="breadcrumb-item">Edit</li></Link>
                        : ''}
                  </ol>
                </div>
                <h4 className="mb-0">{this.state.headingText} Field Crew</h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="validationCustom01"
                                      name="name"
                                      onChange={this.handleOnChange}
                                      placeholder="Name"
                                      required=""
                                      value={this.state.name}
                                    />
                                    {this.validator.message(
                                      "name",
                                      this.state.name,
                                      "required|max:70",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Project Manager
                                    </label>
                                    <DropDown
                                      name="projectManager"
                                      firstOption={{ lable: 'All Project', value: 0 }}
                                      options={projectManagers}
                                      onChange={(e) => this.handleOnChange(e)}
                                      defaultSelected={this.state.projectManagerId}
                                      lable=""
                                    />
                                     {this.validator.message('projectManager', this.state.projectManagerId, 'required', { className: 'text-danger' })}
                                     {this.state.pmEmptyFlag === true ? <p className='text-danger'>The project manager field is required.</p> : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Status
                                    </label>
                                    <DropDown
                                      name="status"
                                      options={[
                                        { lable: "Active", value: "Active" },
                                        {
                                          lable: "Inactive",
                                          value: "Inactive",
                                        },
                                      ]}
                                      onChange={(e) => this.onStatusChange(e)}
                                      defaultSelected={this.state.status}
                                      lable=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row"></div>
                            </form>
                          </div>
                          <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.handleSaveFieldCrew()}
                            >
                              <i className="uil uil-edit"></i>{" "}
                              {this.state.button}
                            </button>
                          </div>
                          <ConfirmBox
                            openModal={isShowConfirmBox}
                            confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                            handleConfirmModal={this.handleConfirmModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
