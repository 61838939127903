import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import AddProject from './component/project/AddProject';
import AddEstimation from './component/project/AddEstimation';
import EditProject from './component/project/EditProject';
import ProjectDocuments from './component/project/component/ProjectDocuments';
import ProjectView from './component/project/component/ProjectView';
import ProjectDocUpload from './component/project/component/ProjectDocUpload';
import Login from './component/auth/Login';
import ForgotPassword from './component/auth/ForgotPassword';
import ResetPassword from './component/auth/ResetPassword';
import SetPassword from './component/auth/SetPassword';
import Layout from './component/layout';
import PrivateRoute from './component/auth/PrivateRoute';
import UserList from './component/user/UserList';
import AddUser from './component/user/AddUser';
import UserProfile from './component/user/UserProfile';
import ChangePassword from './component/user/ChangePassword';
//import ScheduleCalendar from './component/calendar/component/ScheduleCalendar'
import Transport from './component/calendar/component/Transport'
import VendorList from './component/vendor/VendorList';
import AddVendor from './component/vendor/AddVendor';
import QcList from './component/qc/QcList';
import AddQc from './component/qc/AddQc';
import LocationList from './component/location/LocationList';
import AddLocation from './component/location/AddLocation';
import TableList from './component/table/TableList';
import AddTable from './component/table/AddTable';
import ShopList from './component/shop/ShopList';
import AddShop from './component/shop/AddShop';
import DrawingTypeList from './component/drawingType/DrawingTypeList';
import AddDrawingType from './component/drawingType/AddDrawingType';
import FieldCrewList from './component/fieldCrew/FieldCrewList';
import AddFieldCrew from './component/fieldCrew/AddFieldCrew';
import GradeList from './component/GradeMasterData/GradeList';
import AddGrade from './component/GradeMasterData/AddGrade';
import FieldCrewCalendar from './component/calendar/component/FieldCrewCalendar';
import UpdateRecievingDetails from './component/project/component/UpdateRecievingDetails';

import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './assets/css/app.min.css';
import './assets/css/style.css';
import './assets/css/calendar.css';
import './assets/css/custom-media-queries.css';
import './assets/css/custom-style.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import React, { Component, Suspense } from 'react'
import { AbacProvider } from "react-abac";
import { rules } from "./abac.config";

import projectServices from './component/project/services/projectServices';
import { setLoginUserData } from './component/project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from './component/common/Loader';
import ShopMaintenanceList from './component/ShopMaintenance/ShopMaintenanceList';
import AddShopMaintenance from './component/ShopMaintenance/AddShopMaintenance';
import FieldMaintenanceList from './component/FieldMaintenance/FieldMaintenanceList';
import AddFieldMaintenance from './component/FieldMaintenance/AddFieldMaintenance';
import AddFieldMaintenanceitem from './component/FieldMaintenanceTypes/AddFieldMaintenanceitem';
import fieldMaintenancelist from './component/FieldMaintenanceTypes/fieldMaintenancelist';

// MAINTENANCE MODULE IMPORT START HERE----
// (1) SHOP MAINTENANCE
import Maintenance from './component/project/component/Maintenance/Maintenance';
import ShopMasterDataMaintenance from './component/project/component/Maintenance/Shop/ShopMasterDataMaintenance';
import ShopMaintenanceHistory from './component/project/component/Maintenance/Shop/ShopMaintenanceHistory';
import ShopMaintenanceHistoryDetail from './component/project/component/Maintenance/Shop/ShopMaintenanceHistoryDetail';

// (2) FIELD MAINTENANCE
import FieldMasterDataMaintenance from './component/project/component/Maintenance/Field/FieldMasterDataMaintenance';
import FieldMaintenanceHistory from './component/project/component/Maintenance/Field/FieldmaintenanceHistory';
import FieldMaintenanceHistoryDetails from './component/project/component/Maintenance/Field/FieldMaintenanceHistoryDetails';
// MAINTENANCE MODULE IMPORT ENDS  HERE----
//IMPORTING FIELDMAINTENANCEMODULE START HERE----
import Fieldmaintenance from './component/project/component/Maintenance/Field/Fieldmaintenance';
//FIELDMAINTENANCEMODULE IMPORT ENDS  HERE----
// Human Resource import starts----------------
import HumanResource from './component/HumanResourcePage/HumanResource';
import AddEmployee from './component/HumanResourcePage/AddEmployee';



// CERTIFICATE IMPORT  MODULE-----------------------------
import CertificateList from './component/CertificationCheckList/CertificateList';
import AddCertificate from './component/CertificationCheckList/AddCertificate';
import InventoryView from './component/project/component/Inventory/InventoryView';
import ShopDesignationList from './component/ShopDesignation/ShopDesignationList';
import AddShopDesignation from './component/ShopDesignation/AddShopDesignation';
import TitleList from './component/Title/TitleList';
import AddTitle from './component/Title/AddTitle';

// Employee Contact import starts----------------
import EmployeeContact from './component/EmployeeContact/EmployeeContact';
import AddGroup from './component/EmployeeContact/AddGroup';
import AllGroupList from './component/EmployeeContact/AllGroupList';
import SendMail from './component/EmployeeContact/SendMail';
import TemplateLists from './component/EmployeeContact/TemplateLists';
import AddTemplate from './component/EmployeeContact/AddTemplate';
import RestoreUser from './component/user/RestoreUser.js';


import ProjectListingPage from './component/calendar/component/ProjectListingPage.js';

const CreateProject = React.lazy(() => import('./component/project/component/CreateProject'));
const ScheduleCalendar = React.lazy(() => import('./component/calendar/component/ScheduleCalendar'));
const Project = React.lazy(() => import('./component/project/Projects'));
const Dashboard=React.lazy(()=>import('./component/project/component/Dashboard/Dashboard.jsx'));
const ShopPersonal = React.lazy(() => import('./component/calendar/component/ShopPersonal'));
const Receiving = React.lazy(() => import('./component/project/component/Receiving'));
const Inventory = React.lazy(() => import('./component/project/component/Inventory/Inventory'));
const ProjectChart = React.lazy(() => import('./component/project/component/ProjectChartView'));
const ProjectListing = React.lazy(() => import('./component/project/ProjectListing.js'));
const Estimation = React.lazy(() => import('./component/project/Estimation.js'));
const ColorCodeList = React.lazy(() => import('./component/PMColorMasterData/ColorCodeList'));
const AddColorCode = React.lazy(() => import('./component/PMColorMasterData/AddColorCode'));
const EquipmentList=React.lazy(()=> import('./component/EquipmentTypeMasterData/EquipmentList.jsx'));
const AddEquipment=React.lazy(()=>import('./component/EquipmentTypeMasterData/AddEquipment.jsx'));
const RentalEquipment = React.lazy(()=>import('./component/RentalEquipment/RentalEquipment.jsx'));
// -----------------importing archieve projects-------------- //
const ArchievedProjects=React.lazy(()=> import('./component/project/ArchivedProjects.js'))
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      roleKey: "user",
      isLoggedIn: false
    }
  }

  componentDidMount = () => {
    this.getLoginUserData();
  }

  getLoginUserData = () => {
    const { setLoginUserData, loginUserData } = this.props;
    let loginUserDataClone = { ...loginUserData };
    try {
      projectServices.getUserDetailByToken().then((res) => {
        if (res.data.status) {
          let result = res.data.data;
          this.setState({ isLoggedIn: true });
          setLoginUserData({ ...loginUserDataClone, userName: result.name, profileImage: result.profileImage, roleKey: result.roleKey });
          this.setState({ roleKey: result.roleKey });
          // if(window.location.pathname === '/login'){
          //   alert('kokokokooko')
          //   this.props.history.push('/project');
          // }
        }
      }).catch((error) => {
        console.log(error);
        this.setState({ isLoggedIn: false });
      })
    } catch (error) {
      console.log({ error })
    }
  }

  updateRoleKey = (loginRoleKey) => {
    this.setState({ roleKey: loginRoleKey });
  }

  render() {

    let user = {
      "id": 2,
      "name": "user",
      "roles": ["user"],
      "permissions": []
    }
    if (this.state.roleKey !== "") {
      user = {
        "id": localStorage.getItem("userId", "1"),
        "name": localStorage.getItem("userName", "user"),
        "roles": [this.state.roleKey],
        "permissions": []
      }
    }

    if (this.state.roleKey !== "") {
      user = {
        "id": localStorage.getItem("userId", "1"),
        "name": localStorage.getItem("userName", "user"),
        "roles": [this.state.roleKey],
        "permissions": []
      }
    }

    // console.log({isLoggedInApp:this.state.isLoggedIn})

    return (
      <div className="App">
        {this.state.roleKey !== "" &&
          <div>
            <NotificationContainer />
            <AbacProvider
              rules={rules}
              user={user}
              roles={user.roles}
              permissions={user.permissions}
            >
              <BrowserRouter>
                <Switch>
                  <Route exact path="/login" render={(props) => <Login updateRoleKey={this.updateRoleKey} isLoggedIn={this.state.isLoggedIn} {...props} />} />
                  {/* <Route exact path="/" component={Login} />
                  <Route exact path="/login"  component={Login} /> */}
                  <Route exact path="/forgotpassword" component={ForgotPassword} />
                  <Route exact path="/resetpassword/:resetKey" component={ResetPassword} />
                  <Route exact path="/setpassword/:resetKey" component={SetPassword} />

                  <PrivateRoute>
                    <Layout>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/" component={Dashboard} />

                      </Suspense>
                      <Suspense fallback={<Loader />}>
                      <Route exact path="/dashboard" component={Dashboard}/>
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/archive" component={ArchievedProjects}/>
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project" component={Project} />
                      </Suspense>
                      <Route exact path="/project/edit/:projectId" component={EditProject} />
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project/drawing/:projectId" component={CreateProject} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                      <Route exact path="/project/fieldMeasure/:projectId" component={CreateProject} />
                      </Suspense>
                      
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project/material/:projectId" component={CreateProject} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project/shop-fabrication/:projectId" component={CreateProject} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project/freight-delivery/:projectId" component={CreateProject} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/project/field-install/:projectId" component={CreateProject} />
                      </Suspense>
                      <Route exact path="/project/project-document/:projectId" component={ProjectDocuments} />
                      <Route exact path="/project/project-view/:projectId" component={ProjectView} />
                      <Route exact path="/project/project-doc-upload/:projectId/:ref" component={ProjectDocUpload} />
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/timeOffcalendar" component={ScheduleCalendar} />
                      </Suspense>
                      <Route exact path="/transport" component={Transport} />
                      <Route exact path="/project/add" component={AddProject} />
                      <Route exact path="/estimation/add" component={AddEstimation} />
                      {/* project chart route component */}
                      <Suspense fallback={<Loader />}>
                        <Route exact path={"/chartsProject"} component={ProjectChart} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path={"/listingProjects"} component={ProjectListing} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                      <Route exact path="/estimation" component={Estimation} />
                      </Suspense>
                      {/* field crew calender listing route  */}
                      <Route exact path="/fieldcrewcalender" component={FieldCrewCalendar} />
                      <Route exact path="/user" component={UserList} />
                      <Route exact path="/adduser" component={AddUser} />
                      <Route exact path="/userprofile" component={UserProfile} />
                      <Route exact path="/changepassword" component={ChangePassword} />
                      <Route exact path="/restore/:emailId" component={RestoreUser}/>
                      <Route exact path="/update/:userId" component={AddUser} />
                      <Route exact path="/vendor" component={VendorList} />
                      <Route exact path="/addvendor" component={AddVendor} />
                      <Route exact path="/vendor/:vendorId" component={AddVendor} />
                      <Route exact path="/qc" component={QcList} />
                      <Route exact path="/addqc" component={AddQc} />
                      <Route exact path="/qc/:qcId" component={AddQc} />
                      <Route exact path="/location" component={LocationList} />
                      <Route exact path="/addlocation" component={AddLocation} />
                      <Route exact path="/location/:locationId" component={AddLocation} />
                      <Route exact path="/table" component={TableList} />
                      <Route exact path="/addtable" component={AddTable} />
                      <Route exact path="/table/:tableId" component={AddTable} />
                      <Route exact path="/shop" component={ShopList} />
                      <Route exact path="/addshop" component={AddShop} />
                      <Route exact path="/shop/:shopId" component={AddShop} />
                      <Route exact path="/drawingtype" component={DrawingTypeList} />
                      <Route exact path="/adddrawingtype" component={AddDrawingType} />
                      <Route exact path="/drawingtype/:drawingTypeId" component={AddDrawingType} />
                      <Route exact path="/fieldcrew" component={FieldCrewList} />
                      <Route exact path="/addfieldcrew" component={AddFieldCrew} />
                      <Route exact path="/fieldcrew/:fieldCrewId" component={AddFieldCrew} />
                      <Route exact path="/ShopMaintenance" component={ShopMaintenanceList}></Route>
                      <Route exact path="/AddShopMaintenance" component={AddShopMaintenance} />
                      <Route exact path="/shopMaintenance/:ShopMaintenanceId" component={AddShopMaintenance} />
                      <Route exact path="/FieldMaintenance" component={FieldMaintenanceList} />
                      <Route exact path="/AddFieldMaintenance" component={AddFieldMaintenance} />
                      <Route exact path="/FieldMaintenance/:FieldMaintenanceId" component={AddFieldMaintenance} />
                      <Route exact path="/fieldMaintenancepage" component={fieldMaintenancelist} />
                      <Route exact path="/addfieldMaintenancepage" component={AddFieldMaintenanceitem} />
                      <Route exact path="/fieldMaintenancepage/:fieldMaintenanceid" component={AddFieldMaintenanceitem} />
                      {/* -----rental equipment route starts----- */}
                      <Suspense fallback={<Loader/>}>
                          <Route exact path="/rentalEquipment" component={RentalEquipment} />
                      </Suspense>
                       {/* -----rental equipment route ends----- */}
                      {/* master data Grade component route starts */}
                      <Route exact path="/gradeList" component={GradeList} />
                      <Route exact path="/addGrade" component={AddGrade} />
                      <Route exact path="/gradeList/:gradeId" component={AddGrade} />
                      {/* Grade master data routes ends */}
                      {/* Project managers master data routes starts */}
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/colorCodeList" component={ColorCodeList} />
                      </Suspense>
                      <Suspense fallback={<Loader />}>
                        <Route exact path={"/addColorCode"} component={AddColorCode}></Route>
                        {/* <Route exact path={"/colorCodeList/:colorCodeId"} component={AddColorCode} /> */}
                      </Suspense>

                      {/* Project managers master data routes ends */}

                      {/* MAINTENANCE MODULE ROUTE STARTS HERE*/}
                      
                      {/*(1) SHOP MAINTENANCE */}
                      <Route exact path="/maintenance" component={Maintenance} />
                      <Route exact path="/maintenance/add" component={ShopMasterDataMaintenance} />
                      <Route exact path="/maintenance/edit" component={ShopMasterDataMaintenance} />
                      <Route exact path="/maintenance/view" component={ShopMasterDataMaintenance} />
                      <Route exact path="/maintenance/history" component={ShopMaintenanceHistory} />
                      <Route exact path="/maintenance/history/details" component={ShopMaintenanceHistoryDetail} />

                      {/*(2) FIELD MAINTENANCE */}
                      <Route exact path="/maintenance/field/add" component={FieldMasterDataMaintenance} />
                      <Route exact path="/maintenance/field/edit" component={FieldMasterDataMaintenance} />
                      <Route exact path="/maintenance/field/view" component={FieldMasterDataMaintenance} />
                      <Route exact path="/maintenance/field/history" component={FieldMaintenanceHistory} />
                      <Route exact path="/maintenance/field/history/details" component={FieldMaintenanceHistoryDetails} />
                      {/* MAINTENANCE MODULE ROUTE ENDS HERE  */}

                      {/* receiving route start */}
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/shopPersonal" component={ShopPersonal} />
                        <Route exact path="/receiving" component={Receiving} />
                        <Route exact path="/displayrecieving/:recievingId" component={UpdateRecievingDetails} />
                      </Suspense>
                      {/* receiving route ends */}
                      <Suspense fallback={<Loader />}>
                        <Route exact path="/inventory" component={InventoryView} />
                        {/* <Route exact path="/inventory-view/:shopId" component={InventoryView}/> */}
                      </Suspense>
                      <Route exact path="/certificateList" component={CertificateList} />
                      <Route exact path="/addCertificate" component={AddCertificate} />
                      <Route exact path="/certificateList/:certificateId" component={AddCertificate} />
                      {/* Human resource route --------- */}
                      <Route exact path="/humanResource" component={HumanResource}/>
                      <Route exact path="/addEmployee" component={AddEmployee} />
                      <Route exact path="/addEmployee/:employeeId" component={AddEmployee} />

                      {/* Employee Contact route --------- */}
                      <Route exact path="/employeeContact" component={EmployeeContact}/>
                      <Route exact path="/addGroup" component={AddGroup}/>
                      <Route exact path="/addGroup/:groupId" component={AddGroup} />
                      <Route exact path="/allGroupList" component={AllGroupList}/>
                      <Route exact path="/sendMail/:Id" component={SendMail} />
                      {/* Employee Contact template Routes ------- */}
                      <Route exact path="/templateList" component={TemplateLists}/>
                      <Route exact path="/addTemplate" component={AddTemplate}/>
                      <Route exact path="/addTemplate/:templateId" component={AddTemplate}/>


                      {/* Shop Designation route --------- */}
                      <Route exact path="/shopDesignation" component={ShopDesignationList}/>
                      <Route exact path="/addShopDesignation" component={AddShopDesignation} />
                      {/* Title route --------- */}
                      <Route exact path="/title" component={TitleList}/>
                      <Route exact path="/addTitle" component={AddTitle} />
                      <Route exact path ="/dropDownPage" component={ProjectListingPage} />
                      {/*----------- Equipment List Master Data route starts ------- */}
                      <Suspense fallback={<Loader />}>
                      <Route exact path="/equipmentList" component={EquipmentList} />
                      <Route exact path="/addEquipment" component={AddEquipment} />
                      <Route exact path="/equipmentList/:equipmentId" component={AddEquipment} />
                      </Suspense>
                    </Layout>
                  </PrivateRoute>
                </Switch>
              </BrowserRouter>
            </AbacProvider>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setLoginUserData: setLoginUserData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
