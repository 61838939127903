import axios from 'axios';
import { Search } from 'react-bootstrap-icons';
import { getLoginToken } from '../../helpers/Util';
import { async } from 'q';



const getHeader = () => {
    const token = localStorage.getItem("user-token");
    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    };
    return headers;
}

const URL = process.env.REACT_APP_API_URL;

const getProjects = async (queryStr) => {
    return await axios.get(`${URL}/projects${queryStr}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getCompletedAndInProgressProjects=async(queryStr)=>{
    return await axios.get(`${URL}/projects${queryStr}`, {
        headers: getHeader()
    }).then((reponse) => {
        console.log("response initiatd")
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getProjectById = async (id) => {

    return await axios.get(`${URL}/projects/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);



    });
}
const getSearchedProjectById = async (id,searchVar, filters) => {
    let searchParam = searchVar.trim();
     return await axios.get(`${URL}/projects/scope-items/${id}?filter[where][type]=${'MANUAL'}&filter[where][projectId]=${id}&filter[where][title][like]=%${searchParam}%`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const updateProjectById = async (id, payload) => {
    return await axios.patch(`${URL}/projects/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//getScopeItems related to a particular project Api call------
const getScopeItemsByProjectId = async (id) => {
    return await axios.get(`${URL}/projects/scope-items/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getEstimationById = async (id) => {

    return await axios.get(`${URL}/estimations/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateEstimationById = async (id, payload) => {
    return await axios.patch(`${URL}/estimations/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// delete estimation by Id
const deleteEstimationById = async (id) => {
    return await axios.delete(`${URL}/estimations/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//delete project API functions--------------
const deleteProjectById = async (id) => {
    return await axios.delete(`${URL}/projects/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getDrawingType = async () => {
    return await axios.get(`${URL}/projects/drawing_types`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getDrawingDoc = async (projectId, scopeId) => {
    return await axios.get(`${URL}/projects/detail-drawings/${projectId}/${scopeId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteDrawingDoc = async (projectId, scopeId, removeDocId, fileId) => {
    return await axios.delete(`${URL}/projects/detail-drawings/${projectId}/${scopeId}/${removeDocId}/${fileId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// field Measure api calls
const getFieldMeasureDetails=async(projectId, scopeItemId,)=>{
    return await axios.get(`${URL}/projects/field-measure/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addFieldMeasure=async(projectId, scopeItemId,payLoad)=>{
    // console.log(payLoad);
    
    return await axios.post(`${URL}/projects/field-measure/${projectId}/${scopeItemId}`,payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// measure/{projectId}/{scopeItemId}/{fieldMeasureId}/{id}'
const removeFieldMeasureFiles=async(projectId, scopeItemId,fielMeasureId,id)=>{
    // console.log(payLoad);
    
    return await axios.delete(`${URL}/projects/field-measure/${projectId}/${scopeItemId}/${fielMeasureId}/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// material page services
const getPOMaterialDetails = async (projectId, scopeItemId) => {
    return await axios.get(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}/list`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// PO Section:-
const getPOItem = async (projectId, scopeItemId) => {
    return await axios.get(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getProjectPO = async (projectId, scopeItemId) => {
    return await axios.get(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addPOItem = async (projectId, scopeItemId, payload) => {
    return await axios.post(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deletePOItem = (projectId, scopeItemId, poId) => {
    const data = axios.delete(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}/${poId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const getGradeList = () => {
    const data = axios.get(`${URL}/grades/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const getPOData = async (payload) => {

    const projectId = payload.projectId;
    const scopeItemId = payload.scopeItemId;
    const po_id = payload.po_id
    return await axios.get(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}/${po_id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const savePO_Data = async (payload) => {

    const projectId = payload.projectId;
    const scopeItemId = payload.scopeItemId;
    const id = payload.id
    return await axios.patch(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//---------------- 

const addMaterialItem = async (poid, payload) => {
    return await axios.post(`${URL}/projects/project-po-materials/${poid}/project-materials`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getMaterialItem = (poId) => {
    const data = axios.get(`${URL}/projects/project-po-materials/${poId}/project-materials`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}
const getProjectPoMaterialData = async (id, material) => {
    return await axios.get(`${URL}/projects/project-po-materials/${id}/project-materials/${material}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getProjectPoData = async (projectId, scopeItemId, id) => {
    return await axios.get(`${URL}/projects/project-po-materials/${projectId}/${scopeItemId}/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteMaterialItem = (id, material) => {
    const data = axios.delete(`${URL}/projects/project-po-materials/${id}/project-materials/${material}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const getLocation = () => {
    const data = axios.get(`${URL}/locations/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const getProjectMaterial = (poid) => {
    const data = axios.get(`${URL}/projects/project-po-materials/${poid}/project-materials`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const getVendors = () => {
    const data = axios.get(`${URL}/vendors/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
    return data;
}

const saveMaterialData = async (payload) => {

    const id = payload.projectPoMaterialsId;
    const material = payload.material
    return await axios.patch(`${URL}/projects/project-po-materials/${id}/project-materials/${material}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getMaterialData = async (payload) => {

    const id = payload.id;
    const material = payload.material
    return await axios.get(`${URL}/projects/project-po-materials/${id}/project-materials/${material}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addMaterialLenAndQty = async (projectMaterialId, payload) => {
    return await axios.post(`${URL}/projects/materials/items/${projectMaterialId}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getMaterialLenAndQty = async (itemId) => {
    return await axios.get(`${URL}/projects/materials/items/${itemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getSingleMaterialLenAndQty = async (itemId) => {
    return await axios.get(`${URL}/projects/materials/item/${itemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteMaterialLenAndQty = async (projectMaterialId, id) => {
    return await axios.delete(`${URL}/projects/materials/items/${projectMaterialId}/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const UpdateMaterialLengthQtyRecords = async (itemsArray) => {
    let reqUrl;
    if (Array.isArray(itemsArray.materialItems) && itemsArray.materialItems.length > 0) {
        reqUrl = `${URL}/projects/materials/items`
    } else {
        reqUrl = `${URL}/projects/materials/items`
    }

    return await axios.patch(reqUrl, itemsArray, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}


const addShopFabrication = async (projectId, scopeItemId, payLoad) => {
    return await axios.post(`${URL}/projects/shop-fabrications/${projectId}/${scopeItemId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const updateShopFabricationDates = async (projectId, scopeItemId, payLoad) => {
    return await axios.patch(`${URL}/schedule-calender/shop/update-date/${projectId}/${scopeItemId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getShopFabrication = async (projectId, scopeItemId) => {

    return await axios.get(`${URL}/projects/shop-fabrications/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getSingleShopFabricationItems = async (projectId, scopeItemId, id, shopId) => {
    let reqUrl = `${URL}/projects/shop-fabrications/${projectId}/${scopeItemId}/${id}`;
    // if(shopId!==0){
    //     reqUrl=`${URL}/projects/shop-fabrications/${projectId}/${scopeItemId}/${id}?filter[where][shop]=${shopId}`
    // }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//-----------------------------------------------//
const getFieldCrew = async () => {

    return await axios.get(`${URL}/field-crews/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getTables = async () => {

    return await axios.get(`${URL}/tables/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getStoreds = async () => {

    return await axios.get(`${URL}/locations/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getQcs = async () => {

    return await axios.get(`${URL}/qcs/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addFreightDelivery = async (projectId, scopeItemId, payLoad) => {
    return await axios.post(`${URL}/projects/freight-deliveries/${projectId}/${scopeItemId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getFreightDelivery = async (projectId, scopeItemId) => {

    return await axios.get(`${URL}/projects/freight-deliveries/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getSingleFreightDeliveryRecord = async (projectId, scopeItemId, id) => {
    return await axios.get(`${URL}/projects/freight-deliveries/${projectId}/${scopeItemId}/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const importFreightItems = async (projectFreightDeliveryId, payLoad) => {
    return await axios.post(`${URL}/projects/freight-deliveries/items/import/${projectFreightDeliveryId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addFieldInstall = async (projectId, scopeItemId, payLoad) => {

    return await axios.post(`${URL}/projects/field-install/${projectId}/${scopeItemId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getFieldInstall = async (projectId, scopeItemId) => {

    return await axios.get(`${URL}/projects/field-install/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getSingleFieldInstall = async (projectId, scopeItemId, fieldInstallId) => {
    return await axios.get(`${URL}/projects/field-install/${projectId}/${scopeItemId}/${fieldInstallId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateFieldInstall = async (projectId, scopeItemId, payload) => {

    return await axios.patch(`${URL}/projects/field-install/${projectId}/${scopeItemId}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse.data);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addFreightDelItemsByID = async (projectFreightDeliveryId, payLoad) => {


    // alert(`${URL}/projects/freight-deliveries​/items/${projectFreightDeliveryId}`);
    let reqUrl = `${URL}/projects/freight-deliveries/items/${projectFreightDeliveryId}`;
    return await axios.post(decodeURIComponent(reqUrl), payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addFreightDelItems = async (selectedScope, projectId, payLoad) => {


    // alert(`${URL}/projects/freight-deliveries​/items/${projectFreightDeliveryId}`);
    let reqUrl = `${URL}/projects/freight-deliveries/items/${projectId}/${selectedScope}`;
    return await axios.post(decodeURIComponent(reqUrl), payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getFreightDelSingleItem=async(projectFreightDeliveryId,id)=>{
    let reqUrl = `${URL}/projects/freight-deliveries/items/${projectFreightDeliveryId}/${id}`;
    return await axios.get(decodeURIComponent(reqUrl),  {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const updateFreightDelSingleItem=async(projectFreightDeliveryId,payLoad)=>{
     // '/projects/freight-deliveries/items/{projectFreightDeliveryId}')
    let reqUrl = `${URL}/projects/freight-deliveries/items/${projectFreightDeliveryId}`;
    return await axios.patch(decodeURIComponent(reqUrl), payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getFreightDelItems = async (projectFreightDeliveryId) => {
   
    return await axios.get(`${URL}/projects/freight-deliveries/items/${projectFreightDeliveryId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteFreightDelItems = (projectFreightDeliveryId, itemId) => {
    const data = axios.delete(`${URL}/projects/freight-deliveries/items/${projectFreightDeliveryId}/${itemId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })

    return data;
}

const uploadProjectDocument = async (projectId, payLoad) => {
    //`${URL}/projects​/documents​/${projectId}`
    return await axios.post(`${URL}/projects/documents/${projectId}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}


const getProjectDocument = async (projectId) => {

    return await axios.get(`${URL}/projects/documents/${projectId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteProjectDocument = async (projectId, documentId) => {

    return await axios.delete(`${URL}/projects/documents/${projectId}/${documentId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteSingleProjectDocument = async (projectId, documentId, fileId) => {

    return await axios.delete(`${URL}/projects/documents/${projectId}/${documentId}/${fileId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getCalenderProject = async () => {

    return await axios.get(`${URL}/schedule-calender`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getCalenderTransport = async () => {

    return await axios.get(`${URL}/schedule-calender/transport`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
//----- get api for the item data to get defaultScopeItemData------------//
const getFreightDeliveryDefaultScope = async (filters, projectId) => {
    console.log(filters);
    return await axios.get(`${URL}/projects/scope-items/${projectId}${filters}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getCalenderShop = async (shopId) => {
    let reqUrl = `${URL}/schedule-calender/shop`
    if (Array.isArray(shopId) && shopId.length > 0) {
        reqUrl = `${URL}/schedule-calender/shop?filter[where][shop]=${shopId.join(',')}`
    }
    // if (shopId !== 0) {
    //     reqUrl = `${URL}/schedule-calender/shop?filter[where][shop]=${shopId}`
    // }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
//get list of a projects of a particular project manager-----------
const getProjectByProjectManagerId = async (id) => {
    return await axios.get(`${URL}/users/${id}/projects`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getCalenderFieldCrew = async (fieldCrewId) => {
    let reqUrl = `${URL}/schedule-calender/field-crew`
    if (Array.isArray(fieldCrewId) && fieldCrewId.length > 0) {
        reqUrl = `${URL}/schedule-calender/field-crew-filteredData?fieldCrewIds=${fieldCrewId.join(',')}`
        // reqUrl = `${URL}/schedule-calender/field-crew?filter[where][projectFieldInstallFieldCrews][fkey_fieldcrew.id][inq]=${fieldCrewId.join(',')}`
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });

}
const updateProjectDateById = async (id, payload) => {

    return await axios.patch(`${URL}/schedule-calender/update-date/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const addTimeOff = async (payload) => {
    //console.log("timeofff22",payload)
    return await axios.post(`${URL}/time-off-events`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteTimeOff = async (id) => {
    return await axios.delete(`${URL}/time-off-events/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const editTimeOff = async (id, payload) => {
    return await axios.patch(`${URL}/time-off-events/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getMaterialReport = async (projectId, scopeId) => {

    return await axios.get(`${URL}/projects/project-po-materials/reports/${projectId}/${scopeId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const importMaterialItems = async (projectMaterialId, payload) => {

    return await axios.post(`${URL}/projects/materials/items/import/${projectMaterialId}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getFreightDeliveriesReport = async (projectId, scopeId) => {

    return await axios.get(`${URL}/projects/freight-deliveries/reports/${projectId}/${scopeId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getProjectReport = async (projectId) => {
    return await axios.get(`${URL}/projects/reports/${projectId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getUserDetailByToken = async () => {
    return await axios.get(`${URL}/my-profile`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const updatePasswordByToken = async (payload) => {
    return await axios.post(`${URL}/change-password`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const LogOutUser = async () => {
    return await axios.get(`${URL}/logout`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//Receving Modules-----------------
const getProjectsList = async (queryStr) => {
    let reqUrl;
    console.log(queryStr, "queryStr");
    if (queryStr !== "" || queryStr === undefined) {
        reqUrl = `${URL}/projects/active${queryStr}`
    } else {
        reqUrl = `${URL}/projects/active`;
    }

    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getProjectsListByProjectManagers = async (managerId) => {
    let reqUrl;
    
    if (Array.isArray(managerId) && managerId.length > 0) {
        reqUrl = `${URL}/projects/manager/active?filter[where][manager]=${managerId.join(',')}`
    } else {
        reqUrl = `${URL}/projects/manager/active`;
    }

    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
//Receving Modules-----------------
//funtion to get grade from master data-----------------
const getActiveGrade = async () => {
    return await axios.get(`${URL}/grades/active`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getReceivings = async (filters, queryStr) => {
    let reqUrl = `${URL}/receiving${filters}`
    if (queryStr !== '') {
        reqUrl = `${reqUrl}${queryStr}`
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// function to update the Material module of the receiving data ---------
const updateRecievingMaterialModule = async (projectId, scopeItemId, projectPoMaterialsId, material, payload) => {
    return await axios.patch(`${URL}/receiving/${projectId}/${scopeItemId}/${projectPoMaterialsId}/${material}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })

}
// function to update the Po part of the module  of the receiving data ---------
const updateRecievingPoModule = async (projectId, scopeItemId, projectPoMaterialsId, payload) => {
    return await axios.patch(`${URL}/receiving/${projectId}/${scopeItemId}/${projectPoMaterialsId}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//receiving module ends------

// MAINTENANCE MODULE SECTION STARTS HERE-----
//(1) SHOP MAINTENANCE MODULE---
const getShops = async () => {

    return await axios.get(`${URL}/shops/active`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getShopsDetail = async (shopID, filters) => {
    return await axios.get(`${URL}/shops/${shopID}/maintenance-shop-masters${filters}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Add Shop Maintenance master-------
const addItemDetails = async (shopID, payLoad) => {
    return await axios.post(`${URL}/shops/${shopID}/maintenance-shop-masters`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Update Shop Maintenance master-------
const updateItemDetails = async (shopID, itemID, payLoad) => {
    return await axios.patch(`${URL}/shops/${shopID}/maintenance-shop-masters/${itemID}`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Delete Shop Maintenance Master-----
const deleteShopItem = async (shopID, itemID) => {
    return await axios.delete(`${URL}/shops/${shopID}/maintenance-shop-masters/${itemID}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Get Method for Description data from Master table---
const getShopMaintenanceMasterData = async () => {
    return await axios.get(`${URL}/maintenance-shop-maintenance-types/active`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Add Shop Maintenance details data-------
const addShopMaintenanceDetails = async (itemID, payLoad) => {
    return await axios.post(`${URL}/maintenance-shop-masters/${itemID}/maintenance-shop-data`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getMaintenanceItemDetails = async (itemID) => {
    return await axios.get(`${URL}/maintenance-shop-masters/${itemID}/maintenance-shop-data`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const editShopMaintenanceDetails = async (itemID, payLoad) => {
    return await axios.patch(`${URL}/maintenance-shop-masters/${itemID}/maintenance-shop-data`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getShopHistoryList = async (shopMasterID) => {
    return await axios.get(`${URL}/maintenance-shop-masters/${shopMasterID}/maintenance-shop-master-histories`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getShopHistoryDetails = async (shopMasterID, historyID) => {
    return await axios.get(`${URL}/maintenance-shop-masters/${shopMasterID}/maintenance-shop-master-histories/${historyID}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//(2) FIELD MAINTENANCE MODULE---
const getFields = async () => {
    return await axios.get(`${URL}/maintenance-field-types/active`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getFieldsDetail = async (fieldID, filters) => {
    return await axios.get(`${URL}/maintenance-field-types/${fieldID}/maintenance-field-masters${filters}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const fieldAddItem = async (fieldID, payLoad) => {
    return await axios.post(`${URL}/maintenance-field-types/${fieldID}/maintenance-field-masters`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const fieldUpdateItem = async (fieldID, fieldItemId, payLoad) => {
    return await axios.patch(`${URL}/maintenance-field-types/${fieldID}/maintenance-field-masters/${fieldItemId}`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteFieldItem = async (fieldID, fieldItemId) => {
    return await axios.delete(`${URL}/maintenance-field-types/${fieldID}/maintenance-field-masters/${fieldItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Get Field Method for Description data from Master table---
const getFieldMaintenanceMasterData = async () => {
    return await axios.get(`${URL}/maintenance-field-maintenance-types/active`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Get Field Method for Description data from Master table---
const getDynamicFieldsByFieldId = async (fieldId) => {
    return await axios.get(`${URL}/maintenance-field-types/${fieldId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Add Field Maintenance details data-------
const addFieldMaintenanceDetails = async (itemID, payLoad) => {
    return await axios.post(`${URL}/maintenance-field-masters/${itemID}/maintenance-field-data`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// update Field Maintenance details data-------
const editFieldMaintenanceDetails = async (itemID, payLoad) => {
    return await axios.patch(`${URL}/maintenance-field-masters/${itemID}/maintenance-field-data`, payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// get Field Maintenance description details data-------
const getFieldMaintenanceItemAndDescriptionDetails = async (itemID) => {
    return await axios.get(`${URL}/maintenance-field-masters/${itemID}/maintenance-field-data`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Field History:
const getFieldHistoryList = async (fieldMasterID) => {
    return await axios.get(`${URL}/maintenance-field-masters/${fieldMasterID}/maintenance-field-master-histories`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// Field History details
const getFieldHistoryDetails = async (fieldMasterID, historyId) => {
    return await axios.get(`${URL}/maintenance-field-masters/${fieldMasterID}/maintenance-field-master-histories/${historyId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//FILTERED DATA
const getFilteredShopData = async (shopID, filters, status) => {
    let requestURL = `/shops/${shopID}/maintenance-shop-masters${filters}`;
    if (status !== '') {
        requestURL = `/shops/${shopID}/maintenance-shop-masters${filters}&filter[where][status]=${status}`;
    }
    return await axios.get(`${URL}` + `${requestURL}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getFilteredFieldData = async (fieldID, filters, status) => {
    let requestURL = `/maintenance-field-types/${fieldID}/maintenance-field-masters${filters}`;
    if (status !== '') {
        requestURL = `/maintenance-field-types/${fieldID}/maintenance-field-masters${filters}&filter[where][status]=${status}`;
    }
    return await axios.get(`${URL}` + `${requestURL}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// Overdues :=>
const getOverdues = async () => {
    return await axios.get(`${URL}/overdues`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const readAllOverdues = async () => {
    return await axios.post(`${URL}/overdues`, {}, {
        headers: getHeader(),
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        console.log(error);
        return Promise.reject(error);
    })
}

// MAINTENANCE MODULE SECTION ENDS HERE ------


//Inventory details api-------------

//function to get the Count of Inventory data--------------------

const getInventoryCount = async (flagValue) => {
    let reqUrl = `${URL}/inventories/count?${flagValue}`;

    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getUsedAvailableInventoryCount = async (countReqParam) => {
    
    let reqUrl = `${URL}/inventories/count?${countReqParam}`;

    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getProjectInventoryDetails = async (filters, flagValue) => {
    // return await axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=InventoryScopeBTscopeItems`, {
    return await axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getCalculatedInventoryDetails = async (filters, quantityType,flagValue) => {
  
    if(quantityType == "usedInventory"){
       
        return await axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][quantityUsed][gt]=0`, {
            headers: getHeader()
        }).then((reponse) => {
            return Promise.resolve(reponse);
        }).catch((error) => {
            return Promise.reject(error);
        })
    }else if(quantityType == "availableInventory"){
        
        return await axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][quantityLeft][gt]=0`, {
            headers: getHeader()
        }).then((reponse) => {
            return Promise.resolve(reponse);
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
    
    // return await axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=InventoryScopeBTscopeItems`, {
    
}

const getProjectInventoryDetailsByPoNumCount = async (filters, flagValue,searchBy,searchPoNum) => {
    const queryStr =  searchBy == 'poNumber' ? 
    //  `-po-data?filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_poNumber&filter[include][0][scope][where][poNumber]=${searchPoNum}&filter[include][1][relation]=fkey_grade&filter[include][2][relation]=fkey_location&filter[include][3][relation]=InventoryScopeBTscopeItems` 
     `-po-filter-data${filters}&isManuallyCreated=${flagValue}&poNumberLike=${searchPoNum}%` 
    :`?filter={"where":{"and": [ {"isManuallyCreated": ${flagValue}},{"jobNumber": ${searchPoNum}} ] },"include": [ {"relation": "fkey_poNumber"},{"relation": "fkey_grade"},{ "relation": "fkey_location"},{"relation": "InventoryScopeBTscopeItems"}]}`;
    return await axios.get(`${URL}/inventories${queryStr}`, { 
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })       
}

const getProjectInventoryDetailsByPoNum = async (filters, flagValue,searchBy,searchPoNum) => {
    const queryStr =  searchBy == 'poNumber' ? 
    //  `-po-data?filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_poNumber&filter[include][0][scope][where][poNumber]=${searchPoNum}&filter[include][1][relation]=fkey_grade&filter[include][2][relation]=fkey_location&filter[include][3][relation]=InventoryScopeBTscopeItems` 
     `-po-filter-data${filters}&isManuallyCreated=${flagValue}&poNumberLike=${searchPoNum}%` 
    :`?filter={"where":{"and": [ {"isManuallyCreated": ${flagValue}},{"jobNumber": ${searchPoNum}} ] },"include": [ {"relation": "fkey_poNumber"},{"relation": "fkey_grade"},{ "relation": "fkey_location"},{"relation": "InventoryScopeBTscopeItems"}]}`;
    return await axios.get(`${URL}/inventories${queryStr}`, { 
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })       
}

const getProjectJobNumber = async (flagValue) => {
    let reqUrl = `${URL}/inventories?filter[where][isManuallyCreated]=${flagValue}&filter[fields][jobNumber]=true&filter[order]=jobNumber ASC`;
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })

}

const getManualInventorySuggestions = async (flagValue) => {
    let reqUrl = `${URL}/inventories?filter[where][isManuallyCreated]=${flagValue}&filter[fields][material]=true`;
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })

}

const getProjectInventorySuggestions = async (flagValue) => {
    let reqUrl = `${URL}/inventories?filter[where][isManuallyCreated]=${flagValue}&filter[fields][material]=true`;

    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getProjectInventoryDetailsBySearching = async (filters, queryStr, flagValue, suggestionSelectedFlag, locationFlag, locationId) => {
    let reqUrl;
    if (suggestionSelectedFlag === false) {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][material][like]=%${queryStr}%`;
    } else {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][material]=${queryStr}`;
    }
    if (locationFlag === true && queryStr !== "") {
        if (suggestionSelectedFlag === true) {
            reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[where][location]=${locationId}&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][material]=${queryStr}`;
        } else {
            reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[where][location]=${locationId}&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][material][like]=%${queryStr}%`;

        }
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getManualInventoriesByLocation = async (filters, queryStr, flagValue, searchField) => {
    let reqUrl;
    if (searchField.length > 0) {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItemsfilter[where][material][like]=%${searchField}%`
    } else {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems`
    }

    if (queryStr !== '') {
        reqUrl = `${reqUrl}${queryStr}`
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getProjectInventoriesByLocation = async (filters, queryStr, flagValue, searchField) => {
    let reqUrl;
    if (searchField.length > 0) {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems&filter[where][material][like]=%${searchField}%`
    } else {
        reqUrl = `${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location&filter[include][2][relation]=fkey_poNumber&filter[include][3][relation]=InventoryScopeBTscopeItems`
    }
    if (queryStr !== '') {
        reqUrl = `${reqUrl}${queryStr}`
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addInventoryDetails = async (payload) => {
    return await axios.post(`${URL}/inventories`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const editInventoryDetails = async (id, payload) => {
    return await axios.patch(`${URL}/inventories/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//getInvetory for a single item
const getSingleInventoryDetail = async (id) => {
    return await axios.get(`${URL}/inventories/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteInventoryDetails = async (id) => {
    return await axios.delete(`${URL}/inventories/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//download Inventory Record-----------

const getInventoryRecord = async (isManuallyCreated, location, item, isSearch) => {
    if (location === 0) {
        location = 0;
    }
    if (item === "") {
        item = "false"
    }
    return await axios.get(`${URL}/inventories/reports/${isManuallyCreated}/${location}/${item}/${isSearch}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const deleteMassInventories=async(selectedIds)=>{
    const data = { ids: selectedIds };
    return await axios.delete(`${URL}/inventories`, {
        headers: getHeader(),
        data:data
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getCustomfieldData = async (FieldID, ItemID) => {
    return await axios.get(`${URL}/maintenance-field-types/${FieldID}/maintenance-field-masters/${ItemID}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// shop personal download api calling function
const downShopPersonalReport = async (fieldName, order, searchParam1, searchParam2,searchParam3) => {
    return await axios.get(`${URL}/projects/shop-fabrications/report/${fieldName}/${order}/${searchParam1}/${searchParam2}/${searchParam3}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// daily work ticket api calling---------------------------------------

const getDailyWorkTickets = async (projectId, filters) => {
    console.log(projectId, "????????????");
    return await axios.get(`${URL}/daily-work-statuses?filter[where][projectsId]=${projectId}&filter[order]=id DESC${filters}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addDailyWorkTicket = async (payload) => {
    return await axios.post(`${URL}/daily-work-statuses`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getDailyWorkTicketById = async (id) => {
    return await axios.get(`${URL}/daily-work-statuses/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateDailyWorkTicket = async (id, payLoad) => {
    return await axios.patch(`${URL}/daily-work-statuses/${id}`, payLoad, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteDailyWorkTicketById = async (id) => {
    return await axios.delete(`${URL}/daily-work-statuses/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getDailyWorkTicketCount = async (projectId) => {
    return await axios.get(`${URL}/daily-work-statuses/count?[where][projectsId]=${projectId}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//human resource page Api function call--------
const getHumanResourceCount = async (countReqParam) => {
    return await axios.get(`${URL}/human-resources/count?${countReqParam}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getHumanResourcesEmployees = async (filters,empIds) => {
    let excludeIds = empIds;
    return await axios.get( filters == "No" ? `${URL}/human-resources/?filter={"where":{"id":{"nin":[${excludeIds}]}}}`  :`${URL}/human-resources${filters}&filter[include][0][relation]=fkey_shopdesignation&filter[include][1][relation]=fkey_title&filter[order]=employee_name` , {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getHumanResourcesEmployeesOnCheck = async (filters,empIds) => {
    let excludeIds = empIds;
    return await axios.get( `${URL}/human-resources/?filter[include][0][relation]=fkey_shopdesignation&filter[include][1][relation]=fkey_title` , {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getHumanResourcesEmployeesByTitle = async (titleId,excludeIds) => {
    return await axios.get(`${URL}/human-resources/?filter={"where":{"and":[{"titleId":${titleId}},{"id":{"nin":[${excludeIds}]}}]}}` , {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
} 

const getHumanResourcesEmployeesByShopDesignation = async (shopDesignationId,excludeIds) => {
    return await axios.get(`${URL}/human-resources/?filter={"where":{"and":[{"shopDesignationId":${shopDesignationId}},{"id":{"nin":[${excludeIds}]}}]}}` , {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
} 
const getSearchedHREmployeesBySDAndTitle = async (searchParam,excludeIds,titleId,shopDesignationId,) => {
    console.log("excludeIds",excludeIds);
    let sdId = shopDesignationId == 0 ? '':shopDesignationId;
    let TitId = titleId == 0 ? '':titleId;
    return await axios.get(`${URL}/human-resources/searchData/${searchParam}/?excludeIds=${excludeIds}&titleId=${TitId}&shopDesignationId=${sdId}` , {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
} 
const getSearchedHumanResourcesEmployees = async (searchVar, excludeIds) => { 
    console.log("excludeIds",excludeIds);
    let searchParam = searchVar.trim();  
    return await axios.get(`${URL}/human-resources/search/${searchParam}?excludeIds=${excludeIds}`, {
       headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getHumanResourceEmployeeById = async (id) => {
    return await axios.get(`${URL}/human-resources/?filter[where][id]=${id}&filter[include][0][relation]=fkey_shopdesignation&filter[include][1][relation]=fkey_title`, {
        headers: getHeader()
    }).then((reponse) => {
        // console.log("res--",reponse);
        return Promise.resolve(reponse);
    }).catch((error) => {
        console.log("res--", error);
        return Promise.reject(error);
    })
}
const addHumResourceEmpolpoyee = async (payload) => {

    return await axios.post(`${URL}/human-resources`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateHumanResourceEmployee = async (payload, id) => {
    return await axios.patch(`${URL}/human-resources/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteHumanResourceEmployee = async (id) => {
    return await axios.delete(`${URL}/human-resources/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getHumanResourceCertificates = async (humanResourceId) => {
    return await axios.get(`${URL}/human-resource-certificates?filter[where][humanResourceId]=${humanResourceId}&filter[include][0][relation]=fkey_humanResource&filter[include][1][relation]=fkey_certification&filter[include][2][relation]=upload_fkey`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}




// http://3.15.165.208:3300/human-resource-certificates&filter[where][humanResourceId]=3&filter[include][0][relation]=fkey_humanResource&filter[include][1][relation]=fkey_certification&filter[include][2][relation]=upload_fkey
// axios.get(`${URL}/inventories${filters}&filter[where][isManuallyCreated]=${flagValue}&filter[include][0][relation]=fkey_grade&filter[include][1][relation]=fkey_location`

const addHumResourceCertificate = async (payload) => {
    return await axios.post(`${URL}/human-resource-certificates`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteHumResourceCertificateItem = async (id) => {
    return await axios.delete(`${URL}/human-resource-certificates/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getAllShopDesignations = async () => {
    return await axios.get(`${URL}/shop-designations`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getAllTitles = async () => {
    return await axios.get(`${URL}/titles`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getAllBirthdays = async () => {
    return await axios.get(`${URL}/human-resources/birthdays`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//download Human Resource record -----------

const getHumanResourceRecord = async () => {

    return await axios.get(`${URL}/human-resources/report`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

// 1 - Group
const getGroups = async (filters) => {
    return await axios.get(`${URL}/groups${filters}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// const getGroupName =async(id,filters)=>{   
//     return await axios.get(`${URL}/message-group-users${filters}&filter[where][groupId]=${id}&filter[include][0][relation]=fk_humanresource`,{
//         headers:getHeader()
//     }).then((response)=>{
//         return Promise.resolve(response)
//     }).catch((error)=>{
//         return Promise.reject(error);
//     })
// } 
const editGroupName = async (id, payload) => {
    return await axios.patch(`${URL}/groups/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getGroupDetailsById = async (id) => {
    return await axios.get(`${URL}/groups/${id}`, {  // cross check api 
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getAllGroupCount = async (countReqParam) => {
    return await axios.get(`${URL}/groups/count?${countReqParam}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addEmployeeToGroupCheck = async (groupId, userId, filters) => {
    return await axios.get(`${URL}/message-group-users/count?[where][groupId]=${groupId}&[where][humanResourceId]=${userId}&filter[include][0][relation]=fk_humanresource`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getLinkedEmployeeCount = async (id, filters) => {
    return await axios.get(`${URL}/message-group-users/count?[where][groupId]=${id}&filter[include][0][relation]=fk_humanresource`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getLinkedEmployeesWithCount = async (id, filters) => {
    return await axios.get(`${URL}/message-group-users${filters}&filter[where][groupId]=${id}&filter[include][0][relation]=fk_humanresource`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
} 

const getLinkedEmployees = async (id,) => {
    return await axios.get(`${URL}/message-group-users/?filter[where][groupId]=${id}&filter[include][0][relation]=fk_humanresource`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
} 

const deleteLinkedEmployee = async (id) => {
    return await axios.delete(`${URL}/message-group-users/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addGroup = async (payload) => {
    return await axios.post(`${URL}/groups`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addEmployeeToGroup = async (payload) => {
    return await axios.post(`${URL}/message-group-users/addGroupUsers`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteGroup = async (id) => {
    return await axios.delete(`${URL}/groups/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// send mail api 
const sendMail = async (email, payload) => {
    return await axios.post(`${URL}/message-group-users/${email}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const sendGroupMail = async (groupId,isMail, payload) => {
    return await axios.post(`${URL}/message-group-users/${groupId}/${isMail}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const sendIndividualMail = async (email,isMail, payload) => {
    return await axios.post(`${URL}/message-group-users/individualUser/${email}/${isMail}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// template 
const saveTemplate = async (payload) => {
    return await axios.post(`${URL}/user-email-templates`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const editTemplate = async (id, payload) => {
    return await axios.patch(`${URL}/user-email-templates/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getTemplateList = async (filters) => {
    let reqUrl;
    if(filters===""||filters===undefined){
        reqUrl=`${URL}/user-email-templates`
    }else{
        reqUrl=`${URL}/user-email-templates${filters}`
    }
    return await axios.get(reqUrl, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getTemplateDetailsById = async (id) => {
    return await axios.get(`${URL}/user-email-templates/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteTemplate = async (id) => {
    return await axios.delete(`${URL}/user-email-templates/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//  employee contact api call end  

//-----------project archieve section ------------ //

const getArchivedProject = async (filter) => {
    return await axios.get(`${URL}/projects/archive${filter}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteArchivedProjects=async(selectedIds)=>{
    let data = { ids: selectedIds };
    return await axios.delete(`${URL}/projects/archived-projects`, {
        headers: getHeader(),
        data:data
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getArchivedProjectById=async(projectId)=>{
    return await axios.get(`${URL}/projects/archive/${projectId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getArchiveDrawingDoc=async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/detail-drawings/archive/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getArchiveShopFabrications=async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/shop-fabrications/archive/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getArchiveDrawingType=async()=>{
    return await axios.get(`${URL}/projects/drawing_types/archive`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getArchivedFieldInstall=async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/field-install/archive/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getArchivedPoMaterial=async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/project-po-materials/archive/${projectId}/${scopeItemId}/list`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getArchivedFreightDelivery=async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/freight-deliveries/archive/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getArchivedDocuments=async(projectId)=>{
    return await axios.get(`${URL}/projects/documents/archive/${projectId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getUserByEmail=async(emailId)=>{
    return await axios.get(`${URL}/users/findUsersByEmail/${emailId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

// the api service call to add multiple users in the 
const addDefaultScopeTransportItem=async(payload)=>{
    return await axios.post(`${URL}/projects/default-scope`,payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
//-----api calls for the images in transport and freight delivery modules ------------

const getImagesTransport =async(projectId,scopeItemId)=>{
    return await axios.get(`${URL}/projects/freight-deliveries/${projectId}/${scopeItemId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// '/project-freight-delivery-data/{projectId}/{scopeItemId}')
const addImagesTransport =async(projectId,scopeItemId,payLoad)=>{
    return await axios.post(`${URL}/project-freight-delivery-data/${projectId}/${scopeItemId}`,payLoad, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteImagesTransport=async(projectId,scopeItemId,freightDeliveryId,id)=>{
    return await axios.delete(`${URL}/project-freight-delivery-data/${projectId}/${scopeItemId}/${freightDeliveryId}/${id}`,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getRentalEquipmentList=async(filters,queryStr)=>{
  
    let reqUrl=  `${URL}/rental-equipments-data${filters}`

    if (queryStr!== '') {
        reqUrl = `${reqUrl}${queryStr}`
    }
    
    
     
    return await axios.get(reqUrl,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getEquipmentType=async()=>{
    return await axios.get(`${URL}/equipment-types/active`,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addRentalEquipment=async(payload)=>{
    return await axios.post(`${URL}/rental-equipments-data`,payload,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateRentalEquipment=async(id,payload)=>{
    return await axios.patch(`${URL}/rental-equipments-data/${id}`,payload,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getRentalEquipmentById=async(id)=>{
    return await axios.get(`${URL}/rental-equipments/${id}`,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteRentalEquipment=async(id)=>{
    return await axios.delete(`${URL}/rental-equipments-data/${id}`,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const downloadRentalEquipmentReport=async(queryStr)=>{
    console.log(queryStr);
    
    let reqUrl
    if(queryStr!=""||queryStr!==undefined){
        reqUrl=`${URL}/rental-equipment-report${queryStr}`;
    }else{
        reqUrl=`${URL}/rental-equipment-report`
    }
    return await axios.get(`${reqUrl}`,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
// http://3.15.165.208:3300/users/findUsersByEmail/sanskriti.khanna@evontech.com
const projectServices = {
    getProjects,
    getCompletedAndInProgressProjects,
    getProjectById,
    getSearchedProjectById,
    //get scopeItemsByIt,
    getScopeItemsByProjectId,
    updateProjectById,
    getEstimationById,
    updateEstimationById,
    deleteEstimationById,
    //delete project API functions
    deleteProjectById,
    getDrawingType,
    getDrawingDoc,

    getPOMaterialDetails,
    //PO Data:-
    getPOItem,
    getProjectPO,
    addPOItem,
    deletePOItem,
    getGradeList,
    getPOData,
    savePO_Data,

    addMaterialItem,
    getMaterialItem,
    //function added to call the material related to particular po id
    getProjectPoMaterialData,
    getProjectPoData,
    deleteMaterialItem,
    getLocation,
    getVendors,
    getProjectMaterial,
    saveMaterialData,
    getMaterialData,
    addMaterialLenAndQty,
    getMaterialLenAndQty,
    //for getting single material record-----
    getSingleMaterialLenAndQty,
    deleteMaterialLenAndQty,
    // updateParticular Records in material length and qty------
    UpdateMaterialLengthQtyRecords,
    addShopFabrication,
    updateShopFabricationDates,
    getFieldCrew,
    getTables,
    getStoreds,
    getQcs,
    getShopFabrication,
    getSingleShopFabricationItems,
    addFreightDelivery,
    getFreightDelivery,
    getSingleFreightDeliveryRecord,
    importFreightItems,
    addFieldInstall,
    updateFieldInstall,
    getFieldInstall,
    // single Field Install Id------------
    getSingleFieldInstall,
    addFreightDelItems,
    getFreightDelSingleItem,
    updateFreightDelSingleItem,
    getFreightDelItems,
    deleteFreightDelItems,
    uploadProjectDocument,
    getProjectDocument,
    deleteProjectDocument,
    getCalenderProject,
    updateProjectDateById,
    addTimeOff,
    deleteTimeOff,
    editTimeOff,
    getMaterialReport,
    //import api for csv files in material section-----
    importMaterialItems,
    getFreightDeliveriesReport,
    getProjectReport,
    getUserDetailByToken,
    LogOutUser,
    addFreightDelItems,
    deleteDrawingDoc,
    // field measure api calls starts----
    getFieldMeasureDetails,
    addFieldMeasure,
    removeFieldMeasureFiles,
    // field measure api calls ends  ----
    deleteSingleProjectDocument,
    updatePasswordByToken,
    getCalenderTransport,
    getFreightDeliveryDefaultScope,
    getCalenderShop,
    getProjectByProjectManagerId,
    getCalenderFieldCrew,
    getProjectsList,
    getProjectsListByProjectManagers,
    //Receving module starts-----
    getActiveGrade,
    getReceivings,
    updateRecievingMaterialModule,
    updateRecievingPoModule,

    //searching a human resource--------
    getSearchedHumanResourcesEmployees,

    //receiving module ends-------
    // MAINTENANCE STARTS HERE---

    // (1)SHOP
    getShops,
    getShopsDetail,
    addItemDetails,
    updateItemDetails,
    deleteShopItem,
    getShopMaintenanceMasterData,
    addShopMaintenanceDetails,
    getMaintenanceItemDetails,
    editShopMaintenanceDetails,
    getShopHistoryList,
    getShopHistoryDetails,
    getFilteredShopData,

    // (2)FIELD
    getFields,
    getFieldsDetail,
    fieldAddItem,
    fieldUpdateItem,
    deleteFieldItem,
    getFieldMaintenanceMasterData,
    getDynamicFieldsByFieldId,
    addFieldMaintenanceDetails,
    editFieldMaintenanceDetails,
    getFieldMaintenanceItemAndDescriptionDetails,
    getFieldHistoryList,
    getFieldHistoryDetails,
    getFilteredFieldData,

    // Overdues
    getOverdues,
    readAllOverdues,
    getCustomfieldData,
    // MAINTENANCE ENDS HERE---

    //Inventory Module Starts Here ---
    getInventoryCount,
    getUsedAvailableInventoryCount,
    getProjectInventoryDetails,
    getCalculatedInventoryDetails,
    getProjectInventoryDetailsByPoNum,
    getProjectInventoryDetailsByPoNumCount,
    getProjectJobNumber,
    addInventoryDetails,
    editInventoryDetails,
    getSingleInventoryDetail,
    deleteInventoryDetails,
    getManualInventorySuggestions,
    getProjectInventorySuggestions,
    getProjectInventoryDetailsBySearching,
    getManualInventoriesByLocation,
    getProjectInventoriesByLocation,
    //download report API function -----
    getInventoryRecord,
    deleteMassInventories,
    //Inventory  Module Ends  Here ----
    // downShoppersonal report-------
    downShopPersonalReport,
    // daily work tickets module starts----
    getDailyWorkTickets,
    addDailyWorkTicket,
    getDailyWorkTicketById,
    updateDailyWorkTicket,
    deleteDailyWorkTicketById,
    getDailyWorkTicketCount,
    // daily work tickets module ends----
    // human resource api calls-------
    addHumResourceEmpolpoyee,
    getHumanResourceEmployeeById,
    getHumanResourceCount,
    updateHumanResourceEmployee,
    getHumanResourcesEmployees,
    getHumanResourcesEmployeesOnCheck,
    getHumanResourcesEmployeesByTitle,
    getHumanResourcesEmployeesByShopDesignation,
    getSearchedHREmployeesBySDAndTitle,


    deleteHumanResourceEmployee,

    // human resource certifications calls -----
    getHumanResourceCertificates,
    addHumResourceCertificate,
    deleteHumResourceCertificateItem,

    // hr popup api call
    getAllShopDesignations,
    getAllTitles,
    getAllBirthdays,
    getHumanResourceRecord,

    // employee contact 
    getGroups,
    // getGroupName,
    editGroupName,
    getGroupDetailsById,
    getAllGroupCount,
    getLinkedEmployeesWithCount,
    getLinkedEmployees,
    deleteLinkedEmployee,
    addEmployeeToGroupCheck,
    getLinkedEmployeeCount,
    addGroup,
    addEmployeeToGroup,
    deleteGroup,
    //mail
    sendMail,
    sendGroupMail,
    sendIndividualMail,
    //template
    saveTemplate,
    editTemplate,
    getTemplateList,
    getTemplateDetailsById,
    deleteTemplate,
    //-----------project archieve------------//
    getArchivedProject,
    getArchivedProjectById,
    deleteArchivedProjects,
    getArchiveDrawingDoc,
    getArchiveShopFabrications,
    getArchiveDrawingType,
    getArchivedFieldInstall,
    getArchivedPoMaterial,
    getArchivedFreightDelivery,
    getArchivedDocuments,
    getUserByEmail,
    addDefaultScopeTransportItem,
    //--------transport image api calls-----------------//
    getImagesTransport,
    addImagesTransport,
    deleteImagesTransport,
    //------------transport Api calls ends-------------//
    //---------- rental equipment api calls ---------------//
    getRentalEquipmentList,
    getEquipmentType,
    addRentalEquipment,
    updateRentalEquipment,
    getRentalEquipmentById,
    deleteRentalEquipment,
    //download report api
    downloadRentalEquipmentReport,

}

export default projectServices;
