import React, { Component } from 'react'
import { createNotification } from '../helpers/Notification';
import Loader from '../common/Loader';
import { checkValidPasswpord, getLoginToken, isImage, checkImageSize, getApiUrl } from '../helpers/Util';
import axios from 'axios';
import { DropDown } from '../common';
import { FaPen } from 'react-icons/fa';
import ConfirmBox from "../common/ConfirmBox";
import SimpleReactValidator from 'simple-react-validator';
import masterDataService from '../services/MasterDataService'
import AccessDenied from '../common/AccessDenied';
import { rules, Permission } from "../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{},
            name: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            organization: "",
            country: "",
            status: "Active",
            role: 1,
            roleList: [],
            loader: false,
            selectedFile: null,
            imagePreviewUrl: null,
            onchangeImg: false,
            userId: null,
            dbImage: null,
            headingText: 'Add',
            button: 'Save',
            isRolePmFlag: false,
            ColorList: [],
            selectedColor: "xyz",
            isColorNotSelected: false,
            isShowConfirmBox: false,
            isShowRoleChangeConfirmBox: false,
            requiresConfirmation: false,
            confirmRoleChangeFlag: false,
            confirmRoleChange: "",
            userData: [],
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {
        const { match } = this.props;
        console.log(window.history.state.state);
        if (window.history.state.state !== undefined) {
            localStorage.setItem('userListPaginationConstants', JSON.stringify({
                previousPage: window.history.state.state.data.pageChange, previousLimit: window.history.state.state.data.limit,
                previousSkip: window.history.state.state.data.skip, previousSearch: window.history.state.state.data.search
            }))
        }

        this.getRoleList();
        this.getUserList()

        if (match.path === "/update/:userId" && match.params.userId !== null) {
            this.setState({ userId: match.params.userId, headingText: 'Edit', button: 'Update' });
            this.getUserById(match.params.userId);
            this.getAvailableColorCodes(match.params.userId);
        } else {
            this.getAvailableColorCodes("null");
        }
    }
    getUserList = async () => {
        const { search } = this.state;
        const token = getLoginToken();
        let result = true;
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
        const URL = getApiUrl();
        const url = `${URL}/users`;

        //const { resetKey } = this.state;
        // const data = {resetKey};
        // console.log({data});
        await axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status) {
                    let responseData = response.data.data;
                    result = false;
                    this.handleUserList(responseData);
                    //this.setState({ userData: listArr });
                }

            })
            .catch((error) => {

                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                    //this.setState({loader:false});
                } else {
                    createNotification("error", error.response.data.error.message);
                    //this.setState({loader:false});
                }
                console.log({ resetPasswordError: error });

            })

        return result;
    }
    handleUserList = (responseData) => {
        const URL = getApiUrl();
        let listArr = [];
        responseData && responseData.map((item) => {
            let itemData = { ...item };
            itemData.role = itemData.role_fkey && itemData.role_fkey.title;
            itemData.status = itemData.status.charAt(0).toUpperCase() + itemData.status.slice(1);
            itemData.profilePic = itemData.upload_fkey && itemData.upload_fkey.filePath;
            listArr.push(itemData);
        });

        this.setState({ userData: listArr });
    }


    getAvailableColorCodes = (userId) => {
        masterDataService.getAvailableColorCodes(userId).then((response) => {
            if (response.status === 200) {
                let responseData = response.data.data;

                //   result = true;
                let listArr = [];
                responseData && responseData.map((item) => {
                    listArr.push({ lable: item.name, value: item.colorCode });
                });
                this.setState({ ColorList: listArr })
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    getColorCodes = () => {
        masterDataService.getColorCodeList().then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                //   result = true;
                let listArr = [];
                responseData && responseData.map((item) => {
                    listArr.push({ lable: item.name, value: item.colorCode });
                });
                this.setState({ ColorList: listArr })
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
    fileChangedHandler = event => {

        if (isImage(event.target.files[0].name)) {
            if (checkImageSize(event.target.files[0].size)) {
                this.setState({
                    selectedFile: event.target.files[0]
                })


                let reader = new FileReader();

                reader.onloadend = () => {
                    this.setState({
                        imagePreviewUrl: reader.result,
                        onchangeImg: true
                    });
                }

                reader.readAsDataURL(event.target.files[0])
            } else {
                createNotification("info", "File size maximum up 5MB");
            }
        } else {
            createNotification("info", "Please select valid image format!");
        }

    }

    onCancelImg = () => {
        this.setState({
            imagePreviewUrl: null,
            onchangeImg: false
        });
    }

    onSaveImg = () => {

        const token = getLoginToken();
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        this.setState({ loader: true });
        const URL = getApiUrl();
        const url = `${URL}/users/profileUpload`;
        // console.log({getUserById:"yes"});
        //const { password, confirmPassword, } = this.state;
        var fd = new FormData();

        fd.append('profileImage', this.state.selectedFile);
        fd.append('userId', this.state.userId);
        //const data = {name, email,phone,password,country,organization:Number(organization),role:1,status:"Active"};

        axios.post(url, fd, {
            headers: headers
        })
            .then((response) => {
                //if(response.status === 200){
                // let resData = response.data;
                //createNotification("success", "User is added successfully!");
                //  this.handleResetState();
                // this.setState({loader:false});
                //this.props.history.push('/user')
                //  this.setState({name:resData.name,email:resData.email,phoneNum:resData.phone,country:resData.country,organization:resData.organization});

                // localStorage.setItem('user-info',JSON.stringify(response.data));
                // }
                this.setState({
                    onchangeImg: false,
                    loader: false,
                    imagePreviewUrl: null,
                    dbImage: response.data.data.filePath
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                    this.setState({ loader: false });
                } else {
                    createNotification("error", error.response.data.error.message);
                    this.setState({ loader: false });
                }
                console.log({ resetPasswordError: error });
            })

    }

    getUserById = (userId) => {
        const token = getLoginToken();
        if (userId !== "") {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
            const URL = getApiUrl();
            const url = `${URL}/users/${userId}`;
            axios.get(url, {
                headers: headers
            })
                .then((response) => {

                    if (response.status) {
                        let resData = response.data.data;
                        if (resData.role_fkey.title === "Project Manager") {
                            this.setState({ isRolePmFlag: true })
                        } else {
                            this.setState({ isRolePmFlag: false })
                        }

                        this.setState({ name: resData.name, email: resData.email, phone: resData.phone, country: resData.country, organization: resData.organization, dbImage: resData.upload_fkey ? resData.upload_fkey.filePath : "", role: resData.role, status: (resData.status == "ACTIVE" ? "Active" : "Inactive"), selectedColor: resData.colorCode });

                        // localStorage.setItem('user-info',JSON.stringify(response.data));
                    }

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        createNotification("error", "Your login session has been expired!");
                        localStorage.clear();
                        this.props.history.push('/login');
                        //this.setState({loader:false});
                    } else {
                        createNotification("error", error.response.data.error.message);
                        //this.setState({loader:false});
                    }
                    console.log({ resetPasswordError: error });
                })
        }
    }

    getRoleList = async () => {
        const token = getLoginToken();
        let result = true;
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
        const URL = getApiUrl();
        const url = `${URL}/roles`;

        await axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    result = true;
                    let listArr = [];
                    responseData && responseData.map((item) => {
                        listArr.push({ lable: item.title, value: item.id });
                    });
                    this.setState({ roleList: listArr });
                }

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    //createNotification("error", "Your login session has been expired!");
                    // localStorage.setItem('user-token',"");
                    //this.props.history.push('/login');
                    //this.setState({loader:false});
                } else {
                    createNotification("error", error.response.data.error.message);
                    //this.setState({loader:false});
                }
                console.log({ resetPasswordError: error });
            })
        return result;
    }

    handleResetState = () => {

        this.setState({
            name: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            organization: "",
            country: "",
            status: "Active",
            imagePreviewUrl: null,
            onchangeImg: false,
            userId: null,
            dbImage: null,
            headingText: 'Add',
            button: 'Save',
            role: 1
        });
    }

    handleOnChange = (event) => {
        if (event.target.name === "password") {
            this.setState({ password: event.target.value });
        } else if (event.target.name === "confirmPassword") {
            this.setState({ confirmPassword: event.target.value });
        } else if (event.target.name === "name") {
            this.setState({ name: event.target.value });
        } else if (event.target.name === "email") {
            this.setState({ email: event.target.value });
        } else if (event.target.name === "phone") {
            this.setState({ phone: event.target.value });
        } else if (event.target.name === "organization") {
            this.setState({ organization: event.target.value });
        } else if (event.target.name === "country") {
            this.setState({ country: event.target.value });
        } else if (event.target.name === "status") {
            this.setState({ status: event.target.value });
        } else if (event.target.name === "role") {
            if (event.target.value === "3") {
                this.setState({ isRolePmFlag: true, selectedColor: "" })
            }
            else {
                this.setState({ isRolePmFlag: false, selectedColor: "xyz", isColorNotSelected: false })
            }
            this.setState({ role: event.target.value });
        }
    }

    handleOnChangeColor = (event) => {
        if (event.target.name === "pmcolor") {
            this.setState({ selectedColor: event.target.value, isColorNotSelected: false });
        }
    }

    handleSaveUser = () => {
        const { name, email, phone, password, organization, country, status, role, userId, selectedColor, isRolePmFlag, confirmRoleChange, confirmRoleChangeFlag, requiresConfirmation } = this.state;
        const { match } = this.props;
        if (this.validator.allValid() && isRolePmFlag === false) {
            if (this.checkInputData()) {
                const token = getLoginToken();

                const headers = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${token}`
                }
                this.setState({ loader: true });
                const URL = getApiUrl();
                let url = "";
                const data = { name, email, phone, country, organization: Number(organization), role: Number(role), status, colorCode: selectedColor };

                if (match.path === "/update/:userId" && userId !== null) {
                    url = `${URL}/users/${userId}`;
                    axios.patch(url, data, { headers })
                        .then((response) => {
                            console.log("API Response:", response);
                            let reqConfirmation = response?.data?.data?.requiresConfirmation;
                            console.log("Requires Confirmation:", reqConfirmation);

                            if (reqConfirmation !== undefined &&response.status === 200 ) {
                                // alert(1)
                                this.setState({ isShowRoleChangeConfirmBox: true, loader: false ,data:data,requiresConfirmation:true}, () => {
                                    console.log("Modal State Updated:", this.state.isShowRoleChangeConfirmBox);
                                });
                                // this.handleChangePmRole(response)
                                // this.handleSetvalues()

                                return; //stops further actions so that the modal can be opened 
                            }else if (response.status === 200) {
                                createNotification("success", "User Updated successfully!");
                                this.handleResetState();
                                this.setState({ loader: false });
                                this.props.history.push('/user');
                            }
                            
                        })
                        .catch((error) => {
                            console.error("API Error:", error);
                            if (error.response?.status === 401) {
                                createNotification("error", "Your login session has expired!");
                                localStorage.clear();
                                this.props.history.push('/login');
                            } else {
                                createNotification("error", error.response?.data?.error?.message || "An error occurred");
                            }
                            this.setState({ loader: false });
                        });


                } else {
                    url = `${URL}/users`;
                    axios.post(url, data, {
                        headers: headers
                    })
                        .then((response) => {
                            if (response.status === 200) {

                                createNotification("success", "User added successfully!");
                                this.handleResetState();
                                this.setState({ loader: false });
                                this.props.history.push('/user')
                            }

                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                createNotification("error", "Your login session has been expired!");
                                localStorage.clear();
                                this.props.history.push('/login');
                                this.setState({ loader: false });
                            } else {

                                //    this.handleConfirmModal();


                                const filteredObjects = this.state.userData.filter(obj => obj.email === this.state.email);

                                if (filteredObjects.length === 0) {
                                    setTimeout(() => {
                                        this.setState({ isShowConfirmBox: true, loader: false });
                                    }, 1500);
                                } else {
                                    createNotification("error", error.response.data.error.message);
                                    this.setState({ loader: false })
                                }

                            }
                            console.log({ resetPasswordError: error });
                        })
                }
                //const { password, confirmPassword, } = this.state;

            }
        } else if (this.validator.allValid() && isRolePmFlag === true && selectedColor !== "0" && selectedColor !== "") {
            // alert(2)
            const token = getLoginToken();

            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
            this.setState({ loader: true });
            const URL = getApiUrl();
            let url = "";
            const data = { name, email, phone, country, organization: Number(organization), role: Number(role), status, colorCode: selectedColor };

            if (match.path === "/update/:userId" && userId !== null) {
                url = `${URL}/users/${userId}`;
                axios.patch(url, data, {
                    headers: headers
                })
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {

                            createNotification("success", "User Updated successfully!");
                            this.handleResetState();
                            this.setState({ loader: false });
                            this.props.history.push('/user')
                        }

                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            createNotification("error", "Your login session has been expired!");
                            localStorage.clear();
                            this.props.history.push('/login');
                            this.setState({ loader: false });
                        } else {
                            createNotification("error", error.response.data.error.message);
                            this.setState({ loader: false });
                        }
                        console.log({ resetPasswordError: error });
                    })

            } else {
                url = `${URL}/users`;
                axios.post(url, data, {
                    headers: headers
                })
                    .then((response) => {
                        if (response.status === 200) {

                            createNotification("success", "User added successfully!");
                            this.handleResetState();
                            this.setState({ loader: false });
                            this.props.history.push('/user')
                        }

                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            createNotification("error", "Your login session has been expired!");
                            localStorage.clear();
                            this.props.history.push('/login');
                            this.setState({ loader: false });
                        } else {


                            const filteredObjects = this.state.userData.filter(obj => obj.email === this.state.email);

                            if (filteredObjects.length === 0) {
                                setTimeout(() => {
                                    this.setState({ isShowConfirmBox: true, loader: false });
                                }, 1500);
                            } else {
                                createNotification("error", error.response.data.error.message);
                                this.setState({ loader: false })
                            }

                        }
                        console.log({ resetPasswordError: error });
                    })
            }
            //const { password, confirmPassword, } = this.state;

        }
        else {
            if (isRolePmFlag === true && (selectedColor === "0" || selectedColor === "")) {
                this.setState({ isColorNotSelected: true });
                this.validator.showMessages();
                this.forceUpdate();
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }

        }
    }

    handleChangePmRole=()=>{
        const{data,confirmRoleChangeFlag,requiresConfirmation,userId}=this.state
        const token = getLoginToken();

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
        this.setState({ loader: true });
        const URL = getApiUrl();
        let url = "";
        if (  requiresConfirmation === true && this.state.confirmRoleChangeFlag === true) {
            let urlNew = `${URL}/users/${userId}?confirmRoleChange=true`;
            axios.patch(urlNew, data, { headers })
                .then((response) => {
                    createNotification("success", "User Updated successfully!");
                    this.handleResetState();
                    this.setState({ loader: false });
                    this.props.history.push('/user');
                })
                .catch((error) => {
                    console.error("Error in second API call:", error);
                });
        } 
    }

    // handleSetvalues = () => {
    //     this.setState({ isShowRoleChangeConfirmBox: true, loader: false }, () => {
    //         console.log(this.state.isShowRoleChangeConfirmBox);
    //     });
    //     return ;
    // }
    handleConfirmModal = (isYes) => {

        if (isYes) {
            this.props.history.push(`/restore/${this.state.email}`);
        } else {
            this.setState({ isShowConfirmBox: false });
        }

    }

    handleConfirmRoleChangeModal = (isYes) => {
       
        if (isYes) {
            this.setState({ confirmRoleChangeFlag: true },()=>{
                this.handleChangePmRole()
            });
        } else {
            this.setState({ isShowRoleChangeConfirmBox: false })
        }
    }

    checkInputData = () => {
        const { name, email, phone, password, confirmPassword, organization, country, selectedColor, isRolePmFlag } = this.state;
        //const {email,password} = this.state;
        let isValidate = true;
        if (name === "") {
            createNotification("error", "User name is required!");
            isValidate = false;
        } else if (email === "") {
            createNotification("error", "Email is required!");
            isValidate = false;
        }

        else if (phone === "") {
            createNotification("error", "Phone Number is required!");
            isValidate = false;
        }

        return isValidate;
    }

    render() {
        const { loader, onchangeImg, imagePreviewUrl, dbImage, userId, ColorList, isShowConfirmBox, isShowRoleChangeConfirmBox ,data} = this.state;
        const imgUrl = imagePreviewUrl ? imagePreviewUrl : dbImage;
        console.log("data in this particular case:", data);
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey !== "" && rules[roleKey][Permission.USER_WRITE]) ? true : false;

        let imagePreview = (<div className="imagePreview"></div>);
        if (imgUrl) {
            imagePreview = (<div className="imagePreview" style={{ "background-image": `url(${imgUrl})` }} >
            </div>);
        }

        return (
            <div>
                {/* <AllowedTo
                    perform={Permission.USER_READ}
                    no={() =><AccessDenied />}
                > */}
                {loader ? (
                    <Loader />
                ) : (
                    <div>
                        {!isAccess ? <AccessDenied /> :

                            <div className="main-content addUser-form">
                                <ConfirmBox
                                    openModal={isShowConfirmBox}
                                    confirmMsg="Are you sure  you want to restore this user?"
                                    handleConfirmModal={this.handleConfirmModal}
                                />
                                <ConfirmBox
                                    openModal={isShowRoleChangeConfirmBox}
                                    confirmMsg="This PM is currently assigned to a field crew. Do you want to change the role? This will remove the PM from the Field Crew Master Data."
                                    handleConfirmModal={this.handleConfirmRoleChangeModal}
                                />


                                <div className="page-content">
                                    <div className="container-fluid">
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <ol className="breadcrumb m-0">
                                                <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                                &nbsp; / &nbsp;
                                                <Link to={`/user`}><li className="breadcrumb-item">User</li></Link>
                                                &nbsp; / &nbsp;
                                                {this.props.match.path == `/adduser` ?
                                                    <Link to={`/adduser`}><li className="breadcrumb-item">Add</li></Link>
                                                    : this.props.match.path == `/update/:userId` ?
                                                        <Link to={`/update/${this.props.match.params.userId}`}><li className="breadcrumb-item">Update</li></Link>
                                                        : ''}
                                            </ol>
                                        </div>
                                        <h4 className="mb-0">{this.state.headingText} User</h4>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card card-block">
                                                    <div className="card-body">
                                                        <div className="wizard clearfix">
                                                            <div className="content clearfix">
                                                                <form className="form-project ">
                                                                    {userId !== null &&
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-md-3 imgUp uploadFile-image">
                                                                                <div className="mb-3 uploadImgWrap">
                                                                                    {imagePreview}
                                                                                    <label className="btn btn-primary">
                                                                                        <FaPen />
                                                                                        <input type="file" className="uploadFile img" value=""
                                                                                            style={{ width: "0px", height: "0px", overflow: "hidden" }} onChange={(e) => this.fileChangedHandler(e)} />
                                                                                    </label>
                                                                                </div>


                                                                            </div>
                                                                            {onchangeImg &&
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-md-2">
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.onSaveImg()} >
                                                                                                <i className="uil uil-save"></i> Save
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-md-2">
                                                                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={this.onCancelImg} >
                                                                                                <i className="uil uil-cancel"></i> Cancel
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        marginBottom:
                                                                                            "10px"
                                                                                    }}>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="validationCustom01">Name    <span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="text" className="form-control" id="validationCustom01" name="name" onChange={this.handleOnChange} placeholder="Name"
                                                                                    required="" value={this.state.name} />
                                                                                {this.validator.message('name', this.state.name, 'required|max:70', { className: 'text-danger' })}
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-4 col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="validationCustom01">Email ID <span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="text" className="form-control" id="validationCustom01"
                                                                                    name="email" onChange={this.handleOnChange} placeholder="Email"
                                                                                    required="" value={this.state.email} disabled={(userId !== null) ? true : ""} />
                                                                                {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="row">
                                                           
                                                             <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom02">Password</label>
                                                                    <input type="password" className="form-control" id="validationCustom02" 
                                                                     name="password" onChange={this.handleOnChange} 
                                                                     placeholder="Password"
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom02">Confirm Password</label>
                                                                    <input type="password" className="form-control" id="validationCustom02" 
                                                                    name="confirmPassword" onChange={this.handleOnChange} placeholder="Password"
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="validationCustom02">Phone Number <span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="text" className="form-control" id="validationCustom02"
                                                                                    name="phone" onChange={this.handleOnChange} placeholder="Phone number"
                                                                                    required="" value={this.state.phone} />
                                                                                {this.validator.message('phone', this.state.phone, 'required|numeric|min:10|max:10', { className: 'text-danger' })}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4 col-sm-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label" for="validationCustom01">Status</label>
                                                                                {/* <input type="text" className="form-control" id="validationCustom01" 
                                                                    name="organization" onChange={this.handleOnChange}placeholder="Company"
                                                                        required="" /> */}
                                                                                <DropDown
                                                                                    name="status"
                                                                                    //firstOption={{lable:'Status',value:0}} 
                                                                                    options={[{ lable: 'Active', value: 'Active' }, { lable: 'Inactive', value: 'Inactive' }]}
                                                                                    onChange={(e) => this.handleOnChange(e)}
                                                                                    defaultSelected={this.state.status}
                                                                                    lable=""
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-sm-12">
                                                                            <div className="mb-3">

                                                                                <label className="form-label" for="validationCustom02">User Role</label>
                                                                                <DropDown
                                                                                    name="role"
                                                                                    //firstOption={{lable:'Status',value:0}} 
                                                                                    options={this.state.roleList}
                                                                                    onChange={(e) => this.handleOnChange(e)}
                                                                                    defaultSelected={this.state.role}
                                                                                    lable=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {this.state.isRolePmFlag === true ?
                                                                            <div className="col-md-4 col-sm-12">
                                                                                <div className="mb-3">

                                                                                    <label className="form-label" for="validationCustom02">Project Manager Color</label>
                                                                                    <DropDown
                                                                                        name="pmcolor"
                                                                                        firstOption={{ lable: 'Select Color', value: 0 }}
                                                                                        options={this.state.ColorList}
                                                                                        onChange={(e) => this.handleOnChangeColor(e)}
                                                                                        defaultSelected={this.state.selectedColor}
                                                                                        lable=""
                                                                                    />
                                                                                    {this.state.isColorNotSelected === true ? <p className='text-danger'>The color field is required . </p> : ""}
                                                                                </div>
                                                                            </div> : ""}

                                                                    </div>

                                                                </form>
                                                            </div>
                                                            <div className="actions clearfix">
                                                                {/* <ul role="menu" aria-label="Pagination">
                                                        <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li>
                                                        <li aria-hidden="false" aria-disabled="false" className="" ><a href="detail-drawing.html"
                                                            role="menuitem">Next</a></li>
                                                        <li aria-hidden="true" style={{ display: "none" }}><a href="#finish" role="menuitem">Finish</a></li>
                                                    </ul> */}
                                                                {/* <button type="button" className="btn btn-primary waves-effect waves-light">
                                                        <i className="uil uil-edit"></i> Edit
                                                    </button> */}
                                                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.handleSaveUser()}>
                                                                    <i className="uil uil-edit"></i> {this.state.button}
                                                                </button>
                                                                {/* <button type="button" className="btn btn-primary waves-effect waves-light">
                                                        <i className="uil uil-edit"></i> Create
                                                    </button>

                                                    <button type="button" className="btn btn-primary waves-effect waves-light">
                                                        <i className="uil uil-edit"></i> Cancel
                                                    </button> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}
                {/* </AllowedTo> */}
            </div>
        )
    }
}


const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({


}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);